import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import __constants from '../../common/constants';
import { Loading } from '@citi-icg-172888/icgds-react';
import LandingHeader from '../header/header.component';
import ContextData from '../../context/context-data.interface';
import ApplicationContext from '../../context/application-context';
import { RAccordion, AccordionItemContent } from '../../ui-kit/accordion/accordion.component';
import { AxiosResponse } from 'axios';
import {
    defaultData,
    parseData
} from './faq.util';
import './faq.style.scss';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import { handleBackOnLeftMenus } from '../../utils/common.util';

interface Props {
    history?: any;
}

interface State {
    faqData?: Array<AccordionItemContent>;
    showLoading?: Boolean;
}

export class XFaqComponent extends React.Component<Props, State>{

    context: ContextData | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            showLoading: true,
            faqData: defaultData()
        }
    }

    successFaqInfo = (response: AxiosResponse<any>) => {
        const data = _.get(response, 'body', null);
        if (!data) {
            return;
        }
        this.setState({ faqData: parseData(data) });
        this.finallyFaqInfo();
    }

    errorFaqInfo = (error: any) => {
        console.log("errorOnlineUserAgreement error::" + error);
        // console.log(error);
        this.finallyFaqInfo();
    }

    finallyFaqInfo() {
        this.setState({ showLoading: false });
    }

    handleBackButtonClick = () => {
        handleBackOnLeftMenus();
    }

    componentDidMount() {
        //do the api call to fetch data
        this.context?.DEVICE.checkDevice(
            (checkDeviceResponse: any) => {
                if (checkDeviceResponse.wirelessProtocol !== "noNetwork") {
                    this.context?.API.COMMON_API.faqInfo(this.successFaqInfo)
                        .then((response: any) => {
                            //console.log('====>', response)
                            if (response.status !== WAIT_FOR_IOS_CALLBACK)
                                this.successFaqInfo(response.data)
                            else
                                console.log('waiting for ios call', response)
                        })
                        .catch((error: any) => this.errorFaqInfo(error));
                } else {
                    this.setState({ showLoading: false });
                }

            },
            (error: any) => {
                this.setState({ showLoading: false });
            },
        );
    }

    render() {
        return (
            <>
                <LandingHeader title={__constants.FAQS} showBackBtn onBackClick={this.handleBackButtonClick} />

                <div className="react-container top-40 auto-overflow" tabIndex={0} style={{ width: '100%', maxWidth: '100%' }}>
                    {
                        this.state.showLoading
                            ? <Loading tip={__constants.LOADING} horizontal className='loading-center' />
                            : <RAccordion
                                content={(this.state.faqData as Array<AccordionItemContent>)}
                                contentClass='faq-content' />
                    }
                </div>
            </>
        );
    }
}

const FaqComponent = (p: Props) => {

    const context = useContext(ApplicationContext);

    const [faqData, setFaqData] = useState(defaultData());
    const [showLoading, setShowLoading] = useState(true);


    useEffect(() => {

        context?.DEVICE.checkDevice(
            (checkDeviceResponse: any) => {
                if (checkDeviceResponse.wirelessProtocol !== "noNetwork") {
                    context?.API.COMMON_API.faqInfo(successFaqInfo)
                        .then((response: any) => {
                            //console.log('====>', response)
                            if (response.status !== WAIT_FOR_IOS_CALLBACK)
                                successFaqInfo(response.data)
                            else
                                console.log('waiting for ios call', response)
                        })
                        .catch((error: any) => errorFaqInfo(error));
                } else {
                    setShowLoading(false);
                }

            },
            (error: any) => {
                setShowLoading(false);
            },
        );

        //focus to header on page load
        _.delay(() => {
            const header: HTMLElement | null = document.querySelector('.header-content .header-text');
            if (header) {
                header.setAttribute("tabIndex", "0");
                header.focus();
            }
        }, (400));

    }, []);


    const successFaqInfo = (response: AxiosResponse<any>) => {
        const data = _.get(response, 'body', null);
        if (!data) {
            return;
        }
        setFaqData(parseData(data));
        finallyFaqInfo();
    }

    const errorFaqInfo = (error: any) => {
        console.log("errorOnlineUserAgreement error::" + error);
        // console.log(error);
        finallyFaqInfo();
    }

    const finallyFaqInfo = () => {
        setShowLoading(false);
    }


    const handleBackButtonClick = () => {
        handleBackOnLeftMenus();
    }


    return (
        <>
            <LandingHeader title={__constants.FAQS} showBackBtn onBackClick={handleBackButtonClick} />

            <div className="react-container top-40 auto-overflow" tabIndex={0} style={{ width: '100%', maxWidth: '100%' }}>
                {
                    showLoading
                        ? <Loading tip={__constants.LOADING} horizontal className='loading-center' />
                        : <RAccordion
                            content={(faqData as Array<AccordionItemContent>)}
                            contentClass='faq-content' />
                }
            </div>
        </>
    );

}

export default FaqComponent;

XFaqComponent.contextType = ApplicationContext;