
import { AccordionItemContent } from '../../ui-kit/accordion/accordion.component';
import __constants from '../../common/constants';
import { useEffect } from 'react';
import _ from 'lodash';
import LeftMenuAccordion from '../../ui-kit/left-menu-accordion/left-menu-accordion.component';

function PrivacyPage() {

    useEffect(() => {
        //focus to privacy-statement-accordion on page load
        _.delay(() => {
            const header: HTMLElement | null = document.querySelector('.citi-accordion');

            if (header) {
                const privacyStatementEle: HTMLElement | null = document.getElementById('accordion-item-header-container-0');
                if (privacyStatementEle) {
                    privacyStatementEle.setAttribute("tabIndex", "0");
                    privacyStatementEle.focus();
                }
            }
        }, (400));
    }, [])

    const privacy: Array<AccordionItemContent> = [
        {
            header: __constants.PRIVACY_STATEMENT,
            content: __constants.PRIVACY_LINKS.PRIVACY_STATEMENT
        },
        {
            header: __constants.NOTICE_AT_COLLECTION,
            content: __constants.PRIVACY_LINKS.NOTICE_AT_COLLECTION
        },
        {
            header: __constants.CA_PRIVACY_HUB,
            content: __constants.PRIVACY_LINKS.CA_PRIVACY_HUB
        }
    ];

    return (
        <>

            <LeftMenuAccordion accordionContent={privacy} />
        </>
    );

}

export default PrivacyPage;