import { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../ui-kit/button/button";
import Alert from "../../../ui-kit/alert/alert.component";
import _ from "../../../lodash";
import __constants from "../../../common/constants";
import { ForgotPasswordResponse } from "../../../api/forgot-password";
import { APP_LOCATIONS } from "../../../routes/routes";
import CBLInput from "../../../ui-kit/cbusol-input/CBLInput";
import { DEVICE_TYPE } from "../../../device/xdevice";
import ApplicationContext from "../../../context/application-context";
import ContextData from "../../../context/context-data.interface";
import { WAIT_FOR_IOS_CALLBACK } from "../../../api/api.core";
import CitiRegEx from "../../../utils/CitiRegEx.util";
import { focusToElementWithClassName, scrollToTheTop } from "../../../utils/common.util";
import { addLogoutTimer } from "../../../utils/timer.util";
import PasswordCheckerComponent from "../ui-kit/password-checker/password-checker.component";
import LandingHeader from "../../header/header.component";
import "../styles.scss";

function ChangePasswordComponent(this: {
    path: APP_LOCATIONS;
    component: () => any;
    analytics: { eventCategory: string } | { eventCategory: string };
}) {
    let history = useHistory();
    let location = useLocation();
    let context: ContextData = useContext(ApplicationContext);
    let isTokenizedUser = false;

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [reEnterPassword, setReEnterPassword] = useState("");
    const [validatePassword, setValidatePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showOtp1Input, setShowOtp1Input] = useState(false);
    const [characterLimitFrom6To10, setCharacterLimitFrom6To10] = useState(false);
    const [characterLimitFrom8To10, setCharacterLimitFrom8To10] = useState(false);
    const [atleastOneUpperCaseLetter, setAtleastOneUpperCaseLetter] =
        useState(false);
    const [atleastOneLowerCaseLetter, setAtleastOneLowerCaseLetter] =
        useState(false);
    const [atLeast1Number, setAtLeast1Number] = useState(false);
    const [
        cannotContainmorethan2IdenticalCharacters,
        setCannotContainmorethan2IdenticalCharacters,
    ] = useState(false);
    const [
        cannotContainSpecialCharactersOrSpaces,
        setCannotContainSpecialCharactersOrSpaces,
    ] = useState(false);
    const [cannotContainSpaces, setCannotContainSpaces] = useState(false);
    const [atleastOneSpecialCharacter, setAtleastOneSpecialCharacter] =
        useState(false);
    const [isPasswordMatch, setIsPasswordMatch] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState("");

    useEffect(() => {
        window.LOGOUT_TIMER_REACT = addLogoutTimer();

        //ADA | Focus on header on initial load
        focusToElementWithClassName(".header-content .header-text", 400);
    }, []);

    const inputInfo: Array<Object> = [
        {
            onChangeFunc: (e: Event) => {
                setCurrentPassword(_.get(e, "target.value", ""));
            },
            label: __constants.INPUT_CURRENT_PASSWORD,
            val: currentPassword,
            type: "password",
            disabled: false,
            showHiddenIcon: true,
        },
        {
            onChangeFunc: (e: Event) => {
                const p = _.get(e, "target.value", "");

                if (
                    (window.isSplCharPwFriendly
                        ? CitiRegEx.setCharacterLimitFrom8To10(p)
                        : CitiRegEx.setCharacterLimitFrom6To10(p)) &&
                    CitiRegEx.atleastOneUpperCaseLetter(p) &&
                    CitiRegEx.atleastOneLowerCaseLetter(p) &&
                    CitiRegEx.atLeast1Number(p) &&
                    notContainIdenticalCharacters(p) &&
                    (window.isSplCharPwFriendly ? !CitiRegEx.containSpaces(p) : true) &&
                    (window.isSplCharPwFriendly
                        ? CitiRegEx.atleastOneSpecialCharacter(p)
                        : true) &&
                    (window.isSplCharPwFriendly
                        ? true
                        : CitiRegEx.cannotContainSpecialCharactersOrSpaces(p)) &&
                    !CitiRegEx.containOtherSpecialCharacter(p)
                ) {
                    setValidatePassword(true);
                } else {
                    setValidatePassword(false);
                }

                if (
                    p.length > 0 &&
                    reEnterPassword.length > 0 &&
                    p === reEnterPassword
                ) {
                    setIsPasswordMatch(true);
                } else {
                    setIsPasswordMatch(false);
                }

                window.isSplCharPwFriendly
                    ? setCharacterLimitFrom8To10(CitiRegEx.setCharacterLimitFrom8To10(p))
                    : setCharacterLimitFrom6To10(CitiRegEx.setCharacterLimitFrom6To10(p));
                setAtleastOneUpperCaseLetter(CitiRegEx.atleastOneUpperCaseLetter(p));
                setAtleastOneLowerCaseLetter(CitiRegEx.atleastOneLowerCaseLetter(p));
                setAtLeast1Number(CitiRegEx.atLeast1Number(p));
                setCannotContainmorethan2IdenticalCharacters(
                    notContainIdenticalCharacters(p)
                );
                if (window.isSplCharPwFriendly) {
                    setCannotContainSpaces(!CitiRegEx.containSpaces(p));
                    setAtleastOneSpecialCharacter(
                        CitiRegEx.atleastOneSpecialCharacter(p) &&
                        !CitiRegEx.containOtherSpecialCharacter(p)
                    );
                } else {
                    setCannotContainSpecialCharactersOrSpaces(
                        CitiRegEx.cannotContainSpecialCharactersOrSpaces(p)
                    );
                }
                setNewPassword(p);
            },
            label: __constants.INPUT_ENTER_NEW_PASSWORD,
            val: newPassword,
            type: "password",
            maxLength: "10",
            disabled: false,
            showHiddenIcon: true,
        },
        {
            onChangeFunc: (e: Event) => {
                const temp = _.get(e, "target.value", "");
                setReEnterPassword(_.get(e, "target.value", ""));

                if (temp.length > 0 && newPassword.length > 0 && temp === newPassword) {
                    setIsPasswordMatch(true);
                } else {
                    setIsPasswordMatch(false);
                }
            },
            label: __constants.INPUT_RE_ENTER_NEW_PASSWORD,
            val: reEnterPassword,
            type: "password",
            maxLength: "10",
            disabled: false,
            showHiddenIcon: true,
        },
    ];

    function _changePasswordSubmit(akamaiData?: string) {
        if (
            location.pathname === APP_LOCATIONS.ChangePasswordComponent ||
            validatePassword
        ) {
            setShowLoading(true);
            setErrorMessage("");
            setShowSuccessMessage("");

            context?.API.LOGIN_SERVICE.changeUpdatePassword(
                {
                    oldPassword: currentPassword,
                    newPassword: newPassword,
                    confirmPassword: reEnterPassword,
                },
                _successForgotPasswordDetermineIfTokenizedUser,
                akamaiData
            )
                .then((response: { status: number; data: any }) => {
                    console.log("Success call for _changePasswordSubmit", response);
                    if (response.status !== WAIT_FOR_IOS_CALLBACK)
                        _successForgotPasswordDetermineIfTokenizedUser(response.data);
                    else console.log("waiting for ios call", response);
                })
                .catch((error: any) => {
                    setShowLoading(false);
                    console.log(error);
                });
        }
    }

    function notContainIdenticalCharacters(pwd: string) {
        const letters = pwd.split("");
        for (let i = 0; i < letters.length; i++) {
            if (letters[i] === letters[i + 1] && letters[i + 1] === letters[i + 2]) {
                return false;
            }
        }
        return true;
    }

    function _disabledBtn() {
        if (showLoading) {
            return true;
        }
        if (currentPassword && validatePassword && isPasswordMatch) {
            return false;
        } else {
            return true;
        }
    }

    function _handleFailedRequest(error: Array<{ [key: string]: any }>) {
        const message = _.get(
            error,
            "[0].value",
            __constants.ERROR_INCORRECT_INFORMATION
        );
        scrollToTheTop(250);
        setErrorMessage(message);
        setShowLoading(false);
    }

    function _successForgotPasswordDetermineIfTokenizedUser(
        response: ForgotPasswordResponse
    ) {
        console.log("_successForgotPasswordDetermineIfTokenizedUser");
        if (_.get(response, "success", false)) {
            if (_.get(response, "tokenizedUser", false)) {
                //TODO insert auto token code here
                isTokenizedUser = true;
                console.log("LOGIN OTP1 part 1: ........... ");
                if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
                    console.log(
                        "IS USER REGISTERED::::: " + window.DEVICE_INFO.registered
                    );
                }
                setIsSuccess(true);
                setShowOtp1Input(true);
            } else {
                setIsSuccess(true);
            }
            scrollToTheTop(250);
            setShowSuccessMessage(_.get(response, "body.successMessage", ""));
        } else {
            setIsSuccess(false);
            _handleFailedRequest(_.get(response, "errors", []));
        }
        setShowLoading(false);
    }

    function _renderSuccessAlert() {
        return (
            <div className="update-password-submit-alert">
                <Alert
                    type="success"
                    title={"Success"}
                    content={showSuccessMessage}
                    className="forgot-password-alert-success"
                />
            </div>
        );
    }

    function _onContinueBtnClick() {
        if (
            currentPassword.length > 0 &&
            newPassword.length > 0 &&
            reEnterPassword.length > 0 &&
            validatePassword &&
            newPassword === reEnterPassword
        ) {
            if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
                var args = [{ foo: "bar" }];
                context?.DEVICE.getAkamai(
                    (response: any) => {
                        console.log(
                            "_changePasswordSubmit GET AKAMAI DATA Successful ........... "
                        );
                        let akamaiData = _.get(response, "akamaiData", "");
                        _changePasswordSubmit(akamaiData);
                    },
                    (fail: any) => {
                        console.log("AKAMAI DATA RETRIVAL FAILED");
                    },
                    args
                );
            } else {
                _changePasswordSubmit();
            }
        }
    }

    function _onOKBtnClick() {
        history.push(APP_LOCATIONS.SignInHome);
    }

    function _onBackBtnClick() {
        const pathname = APP_LOCATIONS.SignInHome;
        history.push({
            pathname,
        });
    }

    function _renderInputView(data: { [key: string]: any }) {
        const { label } = data;

        return (
            <>
                <CBLInput
                    key={label}
                    type={data.type ? data.type : "text"}
                    label={label}
                    value={data.val}
                    disabled={data.disabled || showLoading}
                    onChange={data.onChangeFunc}
                    onFocus={data.onFocus ? data.Focus : null}
                    numericInput={data.onlyNumbers ? data.onlyNumbers : false}
                    //showIcon
                    showHiddenIcon={data.showHiddenIcon ? data.showHiddenIcon : false}
                    required
                    isBusinessCode={data.isBusinessCode}
                    maxLength={data.maxLength ? data.maxLength : null}
                    mask
                />
                {data.showError ? (
                    <p className="error-message">{data.showError}</p>
                ) : null}
            </>
        );
    }

    const renderPasswordCheckerContainer = () => {
        return <>
            {
                <PasswordCheckerComponent
                    characterLimitFrom8To10={characterLimitFrom8To10}
                    characterLimitFrom6To10={characterLimitFrom6To10}
                    atLeast1Number={atLeast1Number}
                    atleastOneUpperCaseLetter={atleastOneUpperCaseLetter}
                    atleastOneLowerCaseLetter={atleastOneLowerCaseLetter}
                    atleastOneSpecialCharacter={atleastOneSpecialCharacter}
                    cannotContainSpecialCharactersOrSpaces={
                        cannotContainSpecialCharactersOrSpaces
                    }
                    cannotContainmorethan2IdenticalCharacters={
                        cannotContainmorethan2IdenticalCharacters
                    }
                    newPassword={newPassword}
                    cannotContainSpaces={cannotContainSpaces}
                    isPasswordMatch={isPasswordMatch}
                />
            }
            <div className="password-input-container">
                {_.map(inputInfo, (val: { [key: string]: any }) => {
                    if (val.label === __constants.INPUT_CURRENT_PASSWORD) {
                        if (true) {
                            return <>{_renderInputView(val)}</>;
                        } else {
                            return null;
                        }
                    }

                    if (val.label === __constants.INPUT_ENTER_OTP1 && !showOtp1Input) {
                        return null;
                    }

                    return _renderInputView(val);
                })}
            </div>
        </>
    }

    function _renderContentView() {
        return (
            <div className="react-container password-page top-40 auto-overflow change-password-container">
                {errorMessage.length > 0 ? (
                    <Alert
                        type="error"
                        htmlContent={errorMessage}
                        style={{ marginBottom: "1rem" }}
                    />
                ) : null}

                {isSuccess ? _renderSuccessAlert() : null}

                {!isSuccess && renderPasswordCheckerContainer()}

                <div className={`action-container ${isSuccess ? 'done-action' : ''}`}>

                    {isSuccess ? (
                        <Button
                            color="primary"
                            className="buttonWithMargin"
                            onClick={() => {
                                _onOKBtnClick()
                            }}
                            onKeyDown={(e) => e.key === "Enter" && _onOKBtnClick}
                        >
                            {__constants.DONE}
                        </Button>
                    ) :
                        <>
                            <Button
                                color="primary"
                                disabled={_disabledBtn() || isSuccess}
                                className="buttonWithMargin"
                                showLoading={showLoading}
                                onClick={_onContinueBtnClick}
                                onKeyDown={(e) => e.key === "Enter" && _onContinueBtnClick}
                            >
                                {__constants.SUBMIT}
                            </Button>

                            <Button
                                tabIndex={0}
                                color="secondary"
                                className="buttonWithMargin"
                                style={{ border: "none", boxShadow: "none" }}
                                onClick={_onBackBtnClick}
                                onKeyDown={(e) => e.key === "Enter" && _onBackBtnClick}
                            >
                                {__constants.CANCEL}
                            </Button>
                        </>
                    }
                </div>
            </div>
        );
    }

    return (
        <>
            <LandingHeader
                title={__constants.CHANGE_PASSWORD}
                showBackBtn
                onBackClick={_onBackBtnClick}
            />
            {_renderContentView()}
        </>
    );
}

ChangePasswordComponent.contextType = ApplicationContext;
export default ChangePasswordComponent;
