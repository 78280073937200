import { CSSProperties, ReactNode } from 'react';
import { Button, Icon } from "@citi-icg-172888/icgds-react";
import Loading from '../loading-modal/loadingComponent.component';
import { ButtonProps } from '@citi-icg-172888/icgds-react/Button/interface'
import { analyticsBranded } from '../../utils/piwik.util';
import _ from 'lodash';

interface Props extends ButtonProps {
    showLoading?: boolean
    loadingStyle?: CSSProperties;
    children?: ReactNode;
    isFloating?: boolean;
    ariaLabel?: string
    outline?:boolean
}

const CustomButton = (props: Props) => {

    const {
        showLoading,
        loadingStyle,
        children,
        isFloating,
        ariaLabel
    } = props;



    const handleClick = (e: any) => {
        if (props.onClick) {
            props.onClick(e)
            try {
                const text = (e.target.innerText as string).replace(/(<([^>]+))/gi, '').trim()
                if (text !== '') {
                    analyticsBranded(6, `Button ${text}`)
                }
            } catch { }
        }
    }

    const generateBtnId = (): string => {
        if (props.id) {
            return props.id;
        }

        const customId = 'btn-' + window.location.hash + (_.isString(children) ? '-' + children : '') + '-' + _.get(props, 'color', 'default');
        return customId.replace(/#|\//g, '').replace(/ /g, '-').toLocaleLowerCase();
    }

    return <Button tabIndex={0} {...props} id={generateBtnId()} onClick={handleClick} aria-label={ariaLabel}>
        {
            isFloating ?
                <Icon type="right-double" aria-hidden='true' /> : ''
        }
        {
            showLoading && _.isString(children)
                ? <Loading tip={children} style={loadingStyle ? loadingStyle : { color: '#fff' }} size='small' horizontal />
                : children
        }
    </Button>
}

export default CustomButton;