import { useContext, useEffect } from 'react';
import { El, Indicator } from '@citi-icg-172888/icgds-react';
import { APP_LOCATIONS } from '../../../routes/routes';
import ApplicationContext from '../../../context/application-context';
import './sharking-bell.style.scss';


function ShakingBell() {
  const context = useContext(ApplicationContext);

  useEffect(() => {
    const bellIcon = document.querySelector('.notif-bell-shake .lmn-badge-indicator-icon');
    if (bellIcon) {
      bellIcon.setAttribute("aria-label", "Notifications button");
      bellIcon.setAttribute("role", "button");
    }
  }, []);

  return (
    <El className='lmn-d-flex notif-bell-container' onClick={() => { context.Router.navigate(APP_LOCATIONS.PushNotifLanding) }}>
      <Indicator className='notif-bell-shake' iconClass='notif-bell' iconName='bell-o' count={99} />
    </El>
  );
}

export default ShakingBell;