import { Icon } from "@citi-icg-172888/icgds-react";
import { useHistory } from "react-router";
import { APP_LOCATIONS } from "../../../../routes/routes";
import "./fx-demo-video.style.scss";
import BUILD_UTIL from "../../../../utils/build.util";

interface Props {
    history?: any;
    location?: any;
}
const FxVideoComponent = (props: Props) => {

    let history = useHistory();
    const isSTG = BUILD_UTIL.environment() === 'stg'
    const isUAT = BUILD_UTIL.environment() === 'uat';

    /**
     * This is the function to get the FX video URL based on the environment
     * @returns 
     */
    const getFXVideoURL = () => {
        if (isUAT) {
            return "https://mobileuat.citibusinessonline.com/basqat/citiiwt/html/Pulse_Workflow_Demo.mp4";
        }
        else if (isSTG) {
            return "https://mobilestg.citibusinessonline.com/basqat/citiiwt/html/Pulse_Workflow_Demo.mp4";
        }
        else {
            return "https://mobile.citibusinessonline.com/basprod/citiiwt/html/Pulse_Workflow_Demo.mp4";
        }
    }


    const onBackClick = () => {
        history.push({
            pathname: APP_LOCATIONS.ForeignExchange,
        });
    }

    return <>

        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
            <Icon className="fx-back-button" type="left" role="button"
                aria-label="Back, button" onClick={onBackClick} />
            {/* <iframe
                title="video player"
                width="100%"
                height="100%"
                src={fxDemoVideoURL}
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="fx-video-element"
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            ></iframe> */}

            <video className="fx-video-element" controls loop muted playsInline controlsList="nodownload" preload="metadata">
                <source src={getFXVideoURL()} type="video/mp4" />
                Your browser does not support HTML video.
            </video>
        </div>
    </>
}

export default FxVideoComponent;    
