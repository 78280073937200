import http, { RequestMethod } from "./api.core";
import kPIWIK from "../common/constants/PIWIK";
import kGENERAL from "../common/constants/GENERAL";
import { DEVICE_TYPE } from '../device/xdevice';
import __httpApi from './api.core';
import { AxiosResponse } from 'axios';
// import 
let MOCKED_API: any// from "./mocks/mocks";
let COMMON_MOCKED_API: any


export interface RequireUserResponse {

}

export class CommonAPI {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.ts').default
        // const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.copy.ts').default
        const m2 = require(isProd ? './mocks/prod.mock.ts' : './mocks/common.mock.ts').default;
        // const m2 = require(isProd ? './mocks/prod.mock.ts' : './mocks/common.mock.copy.ts').default;
        MOCKED_API = m        
        COMMON_MOCKED_API = m2
    }

    contactUsInfo(callBack?: Function) {
        let url = `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}contactUsPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}?_=${Date.now()}`;
        return http.makeHttpRequest(RequestMethod.GET, url, null, null, callBack, kPIWIK.ChannelEnum.INFO, "contactUsInfo");
    }

    faqInfo(callBack?: Function) {
        let url = `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}faqsPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`;
        return http.makeHttpRequest(RequestMethod.GET, url, null, null, callBack, kPIWIK.ChannelEnum.LOGIN_PLUS, "faqInfo");
    }

    onlineUserAgreement(callBack?: Function) {
        let url = `${kGENERAL.DEMO}${kGENERAL.REACT_ABC}requireUserAgreementPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}?_=${Date.now()}`;
        return http.makeHttpRequest(RequestMethod.GET, url, null, null, callBack, kPIWIK.ChannelEnum.LOGIN_PLUS, "onlineUserAgreement");
    }

    messageCounts(callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}notifications/menu/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.MESSAGING,
            "messageCounts",
            MOCKED_API?.NOTIFICATIONS_MENU);
    }

    getMessagesScript(callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.GET,
            `/messagesScript/${window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB}/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.MESSAGING,
            "messageScript");
    }

    /**
    * Capture Email primary API calls
    */
    emailPrimaryPagecall(pageType: string, callBack?: Function) {
        let apiEndpoint = 'emailCapturePrimaryPage';
        let mockResponse = COMMON_MOCKED_API?.EMAIL_CAPTURE_PRIMARY_PAGE
        if (pageType === 'VERIFY') {
            apiEndpoint = 'emailVerifyPrimaryPage';
            mockResponse = COMMON_MOCKED_API?.EMAIL_VERIFY_PRIMARY_PAGE
        } else if (pageType === 'UPDATE') {
            apiEndpoint = 'emailChangePrimaryPage';
            mockResponse = COMMON_MOCKED_API?.EMAIL_CHANGE_PRIMARY_PAGE
        }

        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}${apiEndpoint}/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.LOGIN_PLUS,
            apiEndpoint,
            mockResponse);
    }

    requestPinViaEmail(params: object, callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}requestPinViaEmail/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.LOGIN_PLUS,
            'requestPinViaEmail',
            COMMON_MOCKED_API?.REQUEST_PIN_VIA_EMAIL);
    }

    skipVerifyEmailPrimaryPage(callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}skipVerifyEmailPrimaryPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.LOGIN_PLUS,
            'skipVerifyEmailPrimaryPage',
            COMMON_MOCKED_API?.SKIP_VERIFY_EMAIL_PRIMARY_PAGE);
    }

    updateEmailPage(params: object, callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}updateEmailPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.LOGIN_PLUS,
            'updateEmailPage',
            COMMON_MOCKED_API?.UPDATE_EMAIL_PAGE);
    }

    /**
    * Verify Email primary API calls
    */
    confirmEmailPage(params: object, callBack?: Function) {

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}confirmEmailPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.LOGIN_PLUS,
            'confirmEmailPage',
            COMMON_MOCKED_API?.CONFIRM_EMAIL_PAGE);
    }


    /**
    * GET call for requireUserAgreementPage
    */
    requireUserAgreementPage(callBack?: Function) {
        let mockResponse = COMMON_MOCKED_API?.USER_AGREEMENT;

        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}requireUserAgreementPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.USER_AGREEMENT,
            'requireUserAgreementPage',
            mockResponse) as Promise<AxiosResponse<RequireUserResponse>>;
    }

    /**
    * post call for acceptUserAgreementPage
    */
    acceptUserAgreementPage(params: { [key: string]: any }, callBack?: Function) {
        let mockResponse = COMMON_MOCKED_API?.ACCEPT_USER_AGREEMENT;
        return __httpApi.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}acceptUserAgreementPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.USER_AGREEMENT,
            'acceptUserAgreementPage',
            mockResponse) as Promise<AxiosResponse<RequireUserResponse>>;
    }
    /**
    * GET call for requireTokenRegistrationPage
    */
    requireTokenRegistrationPage(callBack?: Function) {
        let mockResponse = COMMON_MOCKED_API?.TOKEN_REGISTRATION;

        return __httpApi.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}requireTokenRegistrationPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.USER_AGREEMENT,
            'requireTokenRegistrationPage',
            mockResponse) as Promise<AxiosResponse<RequireUserResponse>>;
    }

}


const __instance__: CommonAPI = new CommonAPI();

export default __instance__;