import _ from "lodash";
import "./list-card.style.scss";
import AccMask from "../../components/wires/initiate/ui-kit/foreign/accMask/AccMask";
import { maskAccountNumber } from "../../components/wires/initiate/ui-kit/foreign/accMask/AccMask";

interface ListCardComponentProps {
    id?: string;
    highlightHeader?: string;
    highlightSubHeader?: any;
    ariaLabelData?: string;
    ariaLabelHeader?: string;
    ariaLabelSubHeader?: string;
    data?: { [key: string]: any };
    onClick?: Function;
    onClickDataContainer?: Function;
    showMask?: boolean // prop to see if the subHeader needs to be masked
    isNotListClickable?: boolean
}

const ListCardComponent = (p: ListCardComponentProps) => {

    const onClick = () => {
        if (p.onClick) {
            p.onClick();
        }
    };

    const onClickDataContainer = () => {
        if (p.onClickDataContainer) {
            p.onClickDataContainer();
        }
    };

    return (
        <div
            id={p.id}
            className={`list-card-container ${p.showMask ? 'no-cursor' : ''}`}
            key={p.id}
            onClick={(e: any) => {
                onClick();
            }}
        >
            <div className="list-highlightHeader"
                id={p.id}
                tabIndex={0}
                aria-label={p.highlightHeader
                    ? p.highlightHeader
                    : (p.highlightSubHeader ? maskAccountNumber(p.highlightSubHeader?.toString()) : '')}>
                        
                <span aria-hidden={true}>
                    {p.highlightHeader}
                </span>

                {p.highlightSubHeader ? (
                    <AccMask ariaLabel={`Heading 2, ${p.highlightSubHeader?.toString()}`} account={p.highlightSubHeader?.toString()} styleClass={`${p.showMask && 'show-pointer'}`} />
                ) : ("")}
            </div>

            <div
                id={p?.id}
                className={`list-data-container ${p.isNotListClickable ? 'cursor-event' : ''}`}
                role={`${p.isNotListClickable ? 'none' : 'button'}`}
                tabIndex={0}
                aria-label={p.ariaLabelData}
                onClick={(e: any) => {
                    onClickDataContainer();
                }}
            >
                {_.map(p.data, (val: string, key: string) => {
                    return (
                        <div className="list-data-subcontainer" key={key} aria-hidden="true">
                            <span aria-hidden="true" className="text-medium key">
                                {key}
                            </span>
                            <span
                                aria-hidden="true"
                                className="text-small accounts-flex accounts-text-right"
                            >
                                {val}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ListCardComponent;
