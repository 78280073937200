import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Button from "../../../ui-kit/button/button";
import CBLInput from '../../../ui-kit/cbusol-input/CBLInput';
import { APP_LOCATIONS } from "../../../routes/routes";
import Loading from "../../../ui-kit/loading-modal/loadingComponent.component";
import CustomModal from "../../../ui-kit/custom-modal/custom-modal.component";
import __req from '../../../api/system-administration.api';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import Alert from '../../..//ui-kit/alert/alert.component';
import '../styles.scss';
import LandingHeader from "../../header/header.component";
import ApplicationContext from '../../../context/application-context';
import { extendSession } from "../utils/timer.utils";
import Dropdown from "../../../ui-kit/dropdown/dropdown.component";
import kSYSTEM_ADMINISTRATION from "../../../common/constants/SYSTEM_ADMINISTRATION";
import kGENERAL from "../../../common/constants/GENERAL";
import { focusToElementWithClassName } from "../../../utils/common.util";
import RadioGroupView from "../../../ui-kit/radio-group/radio-group.component";

function IssueNewPasswordPage() {
    const context = useContext(ApplicationContext);

    const constants_SA = kSYSTEM_ADMINISTRATION;
    const constants = constants_SA.ISSUE_NEW_PASSWORD;
    const [showLoading, setShowLoading] = useState(true);
    const [showUsersList, setShowUsersList] = useState(false);
    const [selectedUser, setSelectedUser] = useState(constants_SA.SELECT_USER);
    const [sortOption, setSortOption] = useState(constants_SA.SORT_BY_USER_ID);
    const [usersList, setUsersList] = useState([]);

    const [password, setPassword] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [errorInfo, setErrorInfo] = useState('');
    const [issueNewPasswordErrorInfo, setIssueNewPasswordErrorInfo] = useState('');
    const [statusMsg, setStatusMsg] = useState();
    const [userInfo, setUserInfo] = useState<any>();
    const [issueNewPasswordResponseCode, setIssueNewPasswordResponseCode] = useState<any>();
    const [disableForm, setDisableForm] = useState(false);

    let history = useHistory();

    useEffect(() => {
        extendSession()
        _sendReqForUsersList();
    }, []);

    useEffect(() => {
        if (issueNewPasswordErrorInfo) {
            focusToElementWithClassName('.error-message', 400);
        } else if (!showLoading) {
            //ADA | Focus on header on initial load
            focusToElementWithClassName('.header-content .header-text', 400);
        }
    }, [showLoading, issueNewPasswordErrorInfo]);

    useEffect(() => {
        extendSession()
        _sendReqForUsersList();
    }, [sortOption]);

    useEffect(() => {
        const headerEle: Element | null = document.querySelector("#cbusol-view");
        if (headerEle) {
            if (showConfirmationModal) {
                headerEle.setAttribute("aria-hidden", "true");
            }
            else {
                headerEle.removeAttribute("aria-hidden");
            }
        }
        return () => {
            if (headerEle) {
                headerEle?.removeAttribute("aria-hidden");
            }
        };
    }, [showConfirmationModal]);

    function _onUserSelect(value: any) {
        setShowUsersList(!showUsersList);
        setPassword('');
        setIssueNewPasswordErrorInfo('');
        focusToSelectUserEle();

        if (value === selectedUser) {
            return;
        }
        setSelectedUser(value);
    }

    const _handleOnUserListVisibleChange = (visible: boolean) => {
        if (!visible) {
            focusToSelectUserEle();
            setShowUsersList(visible)
        }
    };

    const focusToSelectUserEle = () => {
        focusToElementWithClassName('.users-list', 150);
    }

    const onSortOptionChanged = (e: any) => {
        const sortOption = e.target.value;
        setSortOption(sortOption);
        setPassword('')
        setIssueNewPasswordErrorInfo('')
    }

    /**
     * After user selection
     */
    function handlePasswordCancelClick() {
        const pathname = APP_LOCATIONS.SignInHome;
        history.push({
            pathname,
        });
    }

    function handlePasswordSubmitClick() {
        extendSession()
        setShowConfirmationModal(true);
        focusToElementWithClassName('.title-container', 200);
    }

    function onPasswordChanged(e: Event) {
        const val = (e.target as any).value;
        setPassword(val);
    }

    const disablePasswordSubmit = () => {

        if (password.length > 3) {
            return false;
        }

        return true;
    }

    /**
    * Confirmation modal
    */
    const _renderConfirmationModal = () => {
        return (
            <CustomModal
                className="issue-new-password-wrapper"
                userName={getUserDetailsForUser().userName}
                title={constants.PASSWORD_CONFIRMATION}
                showModal={showConfirmationModal}
                onContinue={onIssueNewPasswordOkClick}
                onCancel={() => {
                    setShowConfirmationModal(false);
                }}
                showLoading={disableForm}
                modalName=""
            />
        );
    };

    /**
     * API calls
     */
    function _sendReqForUsersList() {
        setSelectedUser(constants_SA.SELECT_USER);

        const params = {
            sortOrder: sortOption === constants_SA.SORT_BY_USER_ID ? "userId" : "userName",
            issuePassword: "true"
        };

        setShowLoading(true);
        __req
            .getUsersList(params, _handleUsersListResp)
            .then((resp) => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleUsersListResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
            })
            .catch(() => {
                setShowLoading(false);
                setErrorInfo(kGENERAL.REQ_FAILED);
            });
    }

    function _handleUsersListResp(resp: { [key: string]: any }) {
        setShowLoading(false);

        if (resp && resp.success && resp.body && resp.body.userInfo) {
            const usersList = resp.body.userInfo;
            setUsersList(usersList);
        }
    }

    function getUserDetailsForUser() {
        const user = selectedUser;
        const indexOfDash = user.indexOf('-');
        let userId = '';
        let userName = '';
        if (indexOfDash > 0 && indexOfDash < user.length) {
            userId = user.substring(0, indexOfDash - 1)
            userName = user.substring(indexOfDash + 2, user.length);
        }

        return { userId, userName };
    }

    function onIssueNewPasswordOkClick() {
        const userDetails = getUserDetailsForUser();
        const userId = userDetails.userId;
        const userName = userDetails.userName;
        let firstName = '';
        let lastName = '';

        if (userName.length > 0) {
            const nameArray = userName.split(' ');
            if (nameArray.length >= 2) {
                firstName = nameArray[0];
                lastName = nameArray[1];
            }
        }
        const params = {
            "userId": userId,
            "firstName": firstName,
            "lastName": lastName,
            "password": password
        };

        extendSession()
        setDisableForm(true);
        __req
            .issueNewPassword(params, _handleIssueNewPasswordResp)
            .then((resp) => {

                if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                    _handleIssueNewPasswordResp(resp.data);
                } else {
                    console.log('waiting for ios call', resp);
                }
                setDisableForm(false);
                setShowConfirmationModal(false);
            })
            .catch(() => {
                setShowConfirmationModal(false);
                setDisableForm(false);
                setIssueNewPasswordErrorInfo(kGENERAL.REQ_FAILED);
            });
    }

    function _handleIssueNewPasswordResp(resp: { [key: string]: any }) {

        if (resp && resp.success && resp.body && resp.body.userInfo) {
            setIssueNewPasswordResponseCode(resp.body.userInfo.responseCode);
            if (resp.body.status) {
                setStatusMsg(resp.body.status);
                setUserInfo(resp.body.userInfo);
            } else {
                setIssueNewPasswordErrorInfo(kGENERAL.REQ_FAILED);
            }
        } else if (resp && !resp.success && resp.errors && resp.errors[0].value) {
            setIssueNewPasswordErrorInfo(resp.errors[0].value);
        } else {
            setIssueNewPasswordErrorInfo(kGENERAL.REQ_FAILED);
        }
    }

    /**
     * UI
     */
    const uiSortOptions = () => {
        return <>
            <RadioGroupView
                parentClassName="sort-type-radio-wrapper"
                radioGroupName='sort-type'
                radioGroupClassName="sort-type"
                disable={showLoading}
                inline={true}
                options={[constants_SA.SORT_BY_USER_ID, constants_SA.SORT_BY_USER_NAME]}
                onChange={onSortOptionChanged}
                value={sortOption}
                radioChildren={(val: string) => {
                    return <span aria-hidden="true">{val}</span>
                }}
            />
        </>
    }

    const _renderPasswordUI = () => {
        return (<>
            <div className="enter-password-container">
                <p tabIndex={0} className="password-instructions">
                    {constants.PASSWORD_INSTRUCTIONS}
                </p>

                <CBLInput
                    required={true}
                    type={'password'}
                    label={constants.ENTER_PASSWORD}
                    value={password}
                    onChange={onPasswordChanged}
                    ariaLabel={constants.ENTER_PASSWORD}
                />
                {issueNewPasswordErrorInfo.length > 0 ?
                    <span className="error-message" >{issueNewPasswordErrorInfo}</span>
                    : null
                }

                <div className="lmn-col">
                    <Button
                        color="primary"
                        disabled={disablePasswordSubmit()}
                        className='buttonWithMargin'
                        onClick={handlePasswordSubmitClick}>
                        {
                            kGENERAL.SUBMIT
                        }
                    </Button>
                    <Button
                        onClick={handlePasswordCancelClick}
                        color="ghost"
                        className='buttonWithMargin'>
                        {
                            kGENERAL.CANCEL
                        }
                    </Button>
                </div>
            </div>
        </>)
    }

    function _renderStatusUI() {

        const userInfoArray = {
            [constants_SA.USER_NAME]: userInfo.userName,
            [constants_SA.USER_ID]: userInfo.userId
        };


        if (userInfo.emailSentTo !== null && userInfo.emailSentTo.length > 0) {
            userInfoArray[constants.TEMPORARY_PASSWORD_SENT_TO] = userInfo.emailSentTo;
        }
        if (issueNewPasswordResponseCode === 'REQUEST_ADDED_TO_QUEUE_PENDING_APPROVAL') {
            delete userInfoArray[constants.TEMPORARY_PASSWORD_SENT_TO];
        }

        return (
            <div className="status-container">

                <div>
                    <Alert
                        type={'success'}
                        title={constants_SA.STATUS}
                        content={statusMsg}
                    />
                </div>

                <div className="user-info-container-bg">
                    {
                        _.map(userInfoArray, (val: { [key: string]: any }, key: number) => {
                            return (
                                <div className="user-info-container"
                                    tabIndex={0}
                                    aria-label={`${key} ${val.toString()}`}>
                                    <label aria-hidden="true" className="label-title">
                                        {key}
                                    </label>

                                    <div aria-hidden="true" className="label-value-container">
                                        <label className="label-separator">
                                            |
                                        </label>
                                        <label className="label-value">
                                            {val.toString()}
                                        </label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <Button
                    color={'primary'}
                    onClick={handlePasswordCancelClick}
                    className='buttonWithMargin'>
                    {
                        constants_SA.OK
                    }
                </Button>
            </div>
        )
    }

    function _renderUserListDropdown() {
        return (
            <div className="users-list-dropdown-container" onClick={() => setShowUsersList(!showUsersList)}>
                <Dropdown
                    className="users-list"
                    disabled={usersList.length <= 0 || showLoading}
                    visible={showUsersList}
                    defaultLabel={constants_SA.SELECT_USER}
                    value={selectedUser}
                    onChange={_onUserSelect}
                    onVisibleChange={_handleOnUserListVisibleChange}
                    showLoading={showLoading}
                    itemArray={usersList}
                />
            </div>
        )
    }

    function _renderUserSelectionContentView() {
        return (
            <div>
                {errorInfo.length > 0 ?
                    <div className="error-alert-container">
                        <Alert
                            type='error'
                            content={errorInfo}
                        />
                    </div> : null
                }
                {!errorInfo &&
                    <div>
                        <p tabIndex={0} aria-label={`Heading 2, ${constants_SA.USER_SELECTION}`} className='title title-text'>
                            <span aria-hidden="true">{constants_SA.USER_SELECTION}</span>
                        </p>

                        <div className="sort-type-radio-wrapper">
                            {uiSortOptions()}
                        </div>

                        {!showLoading
                            ? _renderUserListDropdown()
                            :
                            <div className="loading-container">
                                <Loading
                                    tip={kGENERAL.LOADING}
                                    horizontal
                                    className="loading-center"
                                />
                            </div>
                        }

                        {selectedUser !== constants_SA.SELECT_USER ? _renderPasswordUI() : null}
                    </div>
                }
            </div>
        );
    }

    return (
        <>
            <LandingHeader
                title={constants.TITLE}
                showBackBtn
                onBackClick={() => { context?.Router.navigate(APP_LOCATIONS.SignInHome) }}
            />
            <div className="react-container bg-gray top-40 auto-overflow issue-new-password-container">
                {statusMsg
                    ? _renderStatusUI()
                    : <>
                        {_renderUserSelectionContentView()}
                        {_renderConfirmationModal()}
                    </>
                }
            </div>


        </>
    );
}

export default IssueNewPasswordPage;