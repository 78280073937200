import LandingHeader from "../../header/header.component";
import __constants from '../../../common/constants';
import Loading from '../../../ui-kit/loading-modal/loadingComponent.component';
import { AccordionItemContent, RAccordion } from "../../../ui-kit/accordion/accordion.component";
import './user-agreement.style.scss';
import { useEffect } from "react";
import _ from "lodash";
import { focusToElementWithClassName } from "../../../utils/common.util";


interface UserAgreementProps {
    showLoading: boolean
    data: { [key: string]: any };
    onBackButtonClick: Function
}


const UserAgreement = (p: UserAgreementProps) => {

    useEffect(() => {
        if (!p.showLoading) {
            //ADA | focus on header on first page load
            focusToElementWithClassName('.header-content .header-text', 400);
        }
    }, [p.showLoading])

    const onBackButtonClick = () => {
        if (p.onBackButtonClick) {
            p.onBackButtonClick()
        }
    }


    const createUI = () => {
        return <>
            <LandingHeader title={__constants.USER_AGREEMENT} showBackBtn onBackClick={onBackButtonClick} />
            <div className="react-container bg-gray top-40 auto-overflow" tabIndex={0}>
                {
                    p.showLoading
                        ? <Loading tip={__constants.LOADING} horizontal className='loading-center' />
                        : <>
                            <p className='title'>{p.data?.definations}</p>
                            <p className='text-medium' dangerouslySetInnerHTML={{ __html: p.data?.definationsContent }} />
                            <RAccordion
                                content={(p.data?.content as Array<AccordionItemContent>)}
                                contentClass='agreement-content' />
                        </>
                }
            </div>
        </>
    }
    return createUI()
}

export default UserAgreement;