import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import __constants from '../../../common/constants';
import ApplicationContext from '../../../context/application-context';
import { APP_LOCATIONS } from '../../../routes/routes';
import { registerNotifIfNeeded } from '../push-notif.util';
import './push-notif-unenroll.style.scss';
import CustomModal from '../../../ui-kit/custom-modal/custom-modal.component';
import { useLocation } from 'react-router-dom';


interface PushNotifUnenrollProps {
    onShowNotifViewChange?: Function;
}

function PushNotifUnenroll(p: PushNotifUnenrollProps) {

    const context = useContext(ApplicationContext);
    const [content, setContent] = useState(__constants.PUSH_NOTIF_UNENROLL.CONFIRM);
    const [footer, setFooter] = useState(__constants.CONTINUE);
    const [success, setSuccess] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    let location = useLocation();
    let unEnrollPushNotif = context.Security.getUnEnrollPushNotif();

    useEffect(() => {
        setShowModal(unEnrollPushNotif ?? false);
    }, [unEnrollPushNotif]);

    useEffect(() => {
        if (success) {
            context.Security.setNotificationChecked(false);
        } else {
            context.Security.setNotificationChecked(true);
        }
    }, [success]);

    /**
     * This is turn off the showNotifViewChange state from the header component
     */
    const onShowNotifViewChange = () => {
        if (p.onShowNotifViewChange) {
            p.onShowNotifViewChange();
        }
    }

    /**
    * This event will turn off the push notification toggle and close the modal
    */
    function _onFailed() {

        onShowNotifViewChange();

        context.Security.setUnEnrollPushNotif(false);
        context.Security.setNotificationChecked(true);
        setShowModal(false);
    }

    function _handleFooterBtnClicked() {
        if (footer === __constants.CONTINUE) {
            setShowLoading(true);

            registerNotifIfNeeded(false, (_: boolean, success: boolean) => {
                setShowLoading(false);
                setContent(success ? __constants.PUSH_NOTIF_UNENROLL.SUCCESS : __constants.REQ_FAILED);
                setFooter(__constants.OK);
                setSuccess(success);
            });
            return;
        }

        if (success) {
            // Notification switch is displayed on the left menu When the user is on the History page and logged in
            // take user to Home page after unenroll successfully
            onShowNotifViewChange()

            if (location.pathname === '/push-notif-landing') {
                context.Router.navigate(APP_LOCATIONS.SignInHome);
            } else {
                context.Security.setUnEnrollPushNotif(false);
                setShowModal(false);
            }
            return;
        }

        _onFailed();
    }

    return <>
        <CustomModal
            title={__constants.PUSH_NOTIF}
            showModal={showModal}
            isCloseable={false}
            wrapperClass='notif-unenroll-modal'
            continueButtonTitle={footer}
            cancellable={footer === __constants.CONTINUE}
            onContinue={_handleFooterBtnClicked}
            showLoading={showLoading}
            onCancel={_onFailed}
            body={
                <span>{content}</span>
            }
            modalName={__constants.PUSH_NOTIF_HEADER}
        />
    </>
}

export default PushNotifUnenroll;