const kEVENT_TRACKING = {
    eventTrackingBrowserSiteId: 173,
    eventTrackingIosSiteId: 171,
    eventTrackingAndroidSiteId: 172,
    ChannelEnum: {
        PRE_LOGIN: "PRE_LOGIN",
        LOGIN: "LOGIN",
        LOGOUT: "LOGOUT",
        INFO: "INFO",
        LOGIN_PLUS: "LOGIN_PLUS",
        DEPOSIT_ACCOUNTS: "DEPOSIT_ACCOUNTS",
        CREDIT_ACCOUNTS: "CREDIT_ACCOUNTS",
        CONTROL_ACCOUNTS: "CONTROL_ACCOUNTS",
        APPROVALS: "APPROVALS",
        APPROVALS_DETAIL: "APPROVALS_DETAIL",
        APPROVALS_UPDATE: "APPROVALS_UPDATE",
        AWAITING_TRANSACTIONS_APPROVALS: "AWAITING_TRANSACTIONS_APPROVALS",
        TRANSFERS: "TRANSFERS",
        GENERAL_EXCEPTION: "GENERAL_EXCEPTION",
        RESPONSIVE: "RESPONSIVE",
        POSITIVE_PAY: "POSITIVE_PAY",
        PLANNED_PENDING: "PLANNED_PENDING",
        VIEW_CHECKS: "VIEW_CHECKS",
        COMPLETED_TRANSFER: "COMPLETED_TRANSFER",
        MESSAGING: "MESSAGING",
        ADMIN: "ADMIN",
        BILL_PAY: "BILL_PAY",
        BILL_PAY_CANCEL: "BILL_PAY_CANCEL",
        WIRES: "WIRES",
        ACCOUNT_STATEMENT: "ACCOUNT_STATEMENT",
        MOBILE_TOKEN: "MOBILE_TOKEN",
        FORCE_UPGRADE: "FORCE_UPGRADE",
        MOBILE_BANKING_ENROLLMENT: "MOBILE_BANKING_ENROLLMENT",
        RCD_KEEP_ALIVE: "RCD_KEEP_ALIVE",
        RCD: "REMOTE_CHECK_DEPOSIT",
        ACTION_REQUIRED: "ACTION_REQUIRED",
        PHONE_OTP: "PHONE_OTP",
        EMAIL_OTP: "EMAIL_OTP",
        BIOMETRICS: "BIOMETRICS",
        SYSTEM_ADMINISTRATION: "SYSTEM_ADMINISTRATION",
        MESSAGES: "MESSAGES",
        LOANS: "LOANS",
        USER_AGREEMENT: "USER_AGREEMENT",
        PUSH_NOTIFICATIONS: "PUSH_NOTIFICATIONS",
        CDCB_LOGIN: "CDCB_LOGIN",
        FX_PULSE: "FX_PULSE",
        INSTANT_PAYMENTS: "INSTANT_PAYMENTS"
    },
};

export default kEVENT_TRACKING;
