import React, { Component, createRef, ReactNode} from 'react';
import CurrencyInput from './currency-input.component';
import CBOLPassword from './password.component';
import CBOLText from './text.component';
import ContextData from '../../context/context-data.interface';
import ApplicationContext from '../../context/application-context';
import { AxiosResponse } from 'axios';
import { WAIT_FOR_IOS_CALLBACK } from '../../api/api.core';
import { addLogoutTimer } from '../../utils/timer.util';
import { CurrencyInputRefObject } from './currency-input.component';

interface Props {
    ref?: any,
    type?: string,
    label?: string,
    labelChildern?: ReactNode,
    required?: boolean
    showHiddenIcon?: boolean
    showSearchIcon?: boolean,
    showSearchIconRight?: boolean
    value?: string
    placeholder?: string
    onChange: any
    onBlur?: any
    onFocus?: any,
    onKeyDown?: React.KeyboardEventHandler,
    onKeyUp?: React.KeyboardEventHandler,
    disabled?: boolean,
    maxLength?: number,
    max?: number,
    maxAmount?: number,
    autoFocus?: boolean,
    isBusinessCode?: boolean,
    mask?: boolean,
    numericInput?: boolean,
    prefix?: string,
    suffix?: string,
    wrapperClass?: string
    bodyClass?: string
    ariaLabel?: string
    id?: string
    onAmountChange?: Function
    fractionDigits?: number
    locale?: string,
    onSuffixClick?: Function,
    ariaLabelSuffix?: string,
    inputClass?: string,
    suffixAriaRole?: string,
    ariaRequired?: boolean,
    ariaLabelInput?: string,
    biometricIcon?: string,
    onBiometricIconClick?: Function,
    isSMSOtp?: boolean
    labelToolTip?: string
    disableSuffix?: boolean
}

interface State {
    hidden?: boolean
    businessCodePrefix?: string
}

class CBLInput extends Component<Props, State> {

    currencyInputRef = createRef<CurrencyInputRefObject>();

    context: ContextData | undefined

    configTriggerKeepAlive: boolean = window.CONFIG_TRIGGER_KEEP_ALIVE || false;


    onInputFocus = (e: any) => {

        if (window.CONFIG_TRIGGER_KEEP_ALIVE === true) {
            this.keepClientServerAlive();
        }

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }

    }


    keepClientServerAlive = () => {

        this.context?.API.LOGIN_SERVICE.keepAlive(this.keepAliveSuccess).then((resp: AxiosResponse<any>) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                this.keepAliveSuccess(resp.data);
            }
        })
            .catch((error: any) => {
                console.log('Failed to extend session.')
            })

    }

    keepAliveSuccess = (resp: any) => {

        if (resp.emulated) {
            return;
        }

        if (resp.keepAlive) {
            window.LOGOUT_TIMER_REACT = addLogoutTimer('CBLInput');
        }

    }


    render() {
        return (
            <>
                {

                        (this.props.type === 'password' ||  this.props.type === 'OTP') ?
                        <CBOLPassword {...this.props} onFocus={this.onInputFocus} /> :
                        this.props.type === 'currency' ?
                            <CurrencyInput {...this.props} fractionDigits={2} onFocus={this.onInputFocus} ref={this.currencyInputRef} /> :
                            <CBOLText {...this.props} onSuffixClick={this.props.onSuffixClick} onFocus={this.onInputFocus} />
                }
            </>
        );
    }
}

CBLInput.contextType = ApplicationContext;

export default CBLInput;
