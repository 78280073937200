import React, { FormEvent } from "react";
import { Tooltip } from "@citi-icg-172888/icgds-react";
import _ from '../../../lodash';

import LandingHeader from "../../header/header.component";
import Button from '../../../ui-kit/button/button';
import CBLInput from '../../../ui-kit/cbusol-input/CBLInput'
import Alert from "../../../ui-kit/alert/alert.component";

import __constants from '../../../common/constants';
import ApplicationContext from "../../../context/application-context";
import ContextData from "../../../context/context-data.interface";
import CitiRegEx from "../../../utils/CitiRegEx.util";
import { getDeviceInfo } from "../../../utils/mobile.common.util";
import { compareVersion } from '../../../utils/common.util';
import { RegisterTokenResponse } from "../../../api/login.api";
import { APP_LOCATIONS } from "../../../routes/routes";
import { addLogoutTimer } from "../../../utils/timer.util";
import { DEVICE_TYPE } from "../../../device/xdevice";
import { maskMiddleText } from "../../../utils/mask.util";
import { WAIT_FOR_IOS_CALLBACK } from "../../../api/api.core";
import { initializeTMX } from '../../secure/biometrics/biometrics.util';
import { getNotifDeviceInfo } from '../../push-notifications/push-notif.util';
import './mfa-login-all.scss';

/**
 * Component for Register Token Page & Forgot PIN Page
 */

interface Props {
    history?: any,
    location?: any
}

interface State {
    businessCode: string,
    userId: string,
    password: string,

    isRegister: boolean,
    isForgotPINWorkflow: boolean,

    //validation specific variables
    errorMessage?: string,

    //input field errors
    businessCodeError?: string,
    userIdError?: string,
    passwordError?: string,

    isLoading?: boolean

    businessCodeUnmasked: string,
    userIdUnmasked: string,

    showForgotPasswordTip: boolean

    // handle the case : go to register token page -> register token and loading -> click cancel -> go to register token page again -> don't navigate to VerificationCodePage
    cancelRegisterToken: boolean
}

export default class MFALoginAll extends React.Component<Props, State> {

    context: ContextData | undefined;
    notifDeviceInfo: object | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            password: '',
            businessCode: '',
            userId: '',
            isRegister: true,
            isForgotPINWorkflow: false,
            errorMessage: '',
            isLoading: false,
            businessCodeUnmasked: '',
            userIdUnmasked: '',
            showForgotPasswordTip: false,
            cancelRegisterToken: false
        };
    }

    componentDidMount() {
        this.context?.Router.__setHistory(this.props.history);
        console.log('loginall->component-did-mount::')

        if (!this.context?.Security.isTriggeredGetDeviceInfo()) {
            getDeviceInfo();
        }

        if (this.props.location?.pathname) {
            this.setState({
                isRegister:
                    (APP_LOCATIONS.RegisterTokenPage === this.props.location.pathname),
                isForgotPINWorkflow:
                    (APP_LOCATIONS.ForgotPIN === this.props.location.pathname)
            });
        }
        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
            this.setState({
                businessCode: "",
                businessCodeUnmasked: "",
                userId: "",
                userIdUnmasked: "",
                password: ""
            });
        }

        window.LOGOUT_TIMER_REACT = addLogoutTimer(this.props.location?.pathname);
    }

    onBusinessCodeBlur = () => {
        const bCode: string = this.state.businessCode;
        const masked = maskMiddleText(bCode);
        this.setState({
            businessCodeUnmasked: bCode ? bCode : '',
            businessCode: masked
        });
    }

    onBusinessCodeFocus = () => {
        const bCode = this.state.businessCodeUnmasked;
        this.setState({
            businessCode: bCode ? bCode : '',
            showForgotPasswordTip: false,
        });

        if (this.props.location?.pathname) {
            this.setState({
                isRegister:
                    (APP_LOCATIONS.RegisterTokenPage === this.props.location.pathname),
                isForgotPINWorkflow:
                    (APP_LOCATIONS.ForgotPIN === this.props.location.pathname)
            });
        }

        this.onInputRemoveErrorMessageFocus();
    }

    onInputRemoveErrorMessageFocus = () => {
        if (this.state.errorMessage && this.state.errorMessage.length > 0) {
            this.setState({ errorMessage: undefined, showForgotPasswordTip: false });
        }
    }

    inputInfo(): Object[] {
        return ([
            {
                onChangeFunc: this.validateBusinessCode,
                id: 'input-enter-business-code',
                label: __constants.INPUT_ENTER_BUSINESS_CODE,
                val: this.state.businessCode,
                error: this.state.businessCodeError,
                onBlur: this.onBusinessCodeBlur,
                onFocus: this.onBusinessCodeFocus,
                type: 'text',
                show: true,
                autoFocus: true,
                businessCode: true,
                onlyNumbers: true,
                disabled: this.state.isLoading
            },
            {
                onChangeFunc: this.validateUserId,
                id: 'input-enter-user-id',
                label: __constants.INPUT_ENTER_USER_ID,
                val: this.state.userId,
                error: this.state.userIdError,
                onBlur: (e: Event) => { this.setState({ userId: maskMiddleText(this.state.userIdUnmasked) }) },
                onFocus: (e: Event) => { this.setState({ userId: this.state.userIdUnmasked, showForgotPasswordTip: false }) },
                type: 'text',
                show: true,
                onlyNumbers: true,
                disabled: this.state.isLoading
            },
            {
                onChangeFunc: (e: Event) => { this.setState({ password: (e.target as any).value }) },
                id: 'input-enter-password',
                label: __constants.INPUT_ENTER_PASSWORD,
                val: this.state.password,
                type: 'password',
                onFocus: (e: Event) => { this.setState({ password: '', showForgotPasswordTip: false }) },
                error: this.state.passwordError,
                enableForgotPasswordLink: true,
                show: true,
                onlyNumbers: false,
                disabled: this.state.isLoading
            }
        ])
    }



    clearSecureStorage = () => {
        this.context?.DEVICE.clearSecureStorage(
            () => {
                console.log('clearSecureStorage success.......... ');
            },
            (error: any) => {
                console.log(error);
                console.log('clearSecureStorage failuer.......... ');
            },
            [{ "foo": "bar" }]
        );
    }

    unenrollNotif = (onDone: Function) => {
        if (!compareVersion('18')) {
            onDone();
            return;
        }

        this.context?.DEVICE?.getPushNotifInfo((resp: any) => {
            console.log('Push Notifications - forgot pin - getPushNotifInfo succeed');

            if (resp && resp.enable && resp.enrolled) {
                getNotifDeviceInfo((success: boolean, deviceInfo?: { [key: string]: any }) => {
                    console.log('Push Notifications - forgot pin - getDeviceInfo: ', success);
                    this.notifDeviceInfo = deviceInfo;
                    onDone(deviceInfo);
                });

                return;
            }

            console.log('Push Notifications - forgot pin - No need to unenroll notifications');
            onDone();
        }, () => {
            console.log('Push Notifications - forgot pin - getPushNotifInfo failed');
            onDone();
        });
    }

    unenrollBiometrics = (onDone: Function) => {
        if (!compareVersion('17')) {
            onDone();
            return;
        }

        this.context?.DEVICE.getBiometricInfo((resp: any) => {
            if (resp && resp.enable && resp.enrolled && (resp.policy === __constants.BIOMETRICS_POLICY.LOGIN)) {

                initializeTMX(
                    __constants.BIOMETRICS_EVENT_TYPE.UNENROLL,
                    true,
                    () => {
                        console.log('forgot pin | biometric - initializeTMX succeed');

                        this.context?.DEVICE.unenrollBiometrics(() => {
                            console.log('forgot pin | biometric - unenrollBiometrics succeed');
                        }, () => {
                            console.log('forgot pin | biometric - unenrollBiometrics failed');
                        });

                        onDone();
                    },
                    () => {
                        console.log('forgot pin | biometric - initializeTMX failed');
                        onDone();
                    }
                );

                return;
            }

            console.log('forgot pin | biometric - No need to clear user data');
            onDone();
        }, () => {
            console.log('forgot pin | biometric - getBiometricInfo failed');
            onDone();
        });
    }

    navigateToVerificationCodePage = (userP86Id: string, registerTokenResponse: RegisterTokenResponse, deviceInfo?: object) => {
        this.clearSecureStorage();
        this.context?.Security.setUserP86Id(userP86Id);

        window.TOKEN_SERIAL_NUMBER = registerTokenResponse.tokenSerialNo;
        this.props.history.push({
            pathname: APP_LOCATIONS.VerificationCodePage,
            state: {
                data: {
                    businessCode: this.state.businessCodeUnmasked,
                    userCode: this.state.userIdUnmasked,
                    size: registerTokenResponse.verificationCodeSize,
                    from: this.props.location.pathname,
                    isForgotPINWorkflow: this.state.isForgotPINWorkflow,
                    cdcb: registerTokenResponse.cdcb,
                    cdcbRebrand: registerTokenResponse.cdcbRebrand,
                    cdcbLogin: registerTokenResponse.cdcbLogin,
                    cdcbLoginId: registerTokenResponse.cdcbLoginId,
                    notifDeviceInfo: deviceInfo,
                    notifEnabled: registerTokenResponse.pushNotifications
                }
            }
        });
    }

    handleForgotPassword = () => {
        document.getElementById('id-frgt-pwd-tip-mfa')?.focus();

        document.getElementById('forgot-password-tooltip')?.focus();

        const ele: HTMLElement | null = document.querySelector('.lmn-tooltip-inner ');
        if (ele) {
            ele.focus();
            ele.setAttribute('tabIndex', '0');
        }
        setTimeout(() => {

            document.getElementById('tooltip6')?.focus();
            const addInfoEle: HTMLElement | null = document.querySelector('.lmn-tooltip.forgot-password-tip');
            if (addInfoEle) {
                addInfoEle.setAttribute("ariaLabel", __constants.FORGOT_PASSWORD_TIP);
                addInfoEle.focus();
            }
        }, 1000);

        this.setState({ showForgotPasswordTip: true });
    }

    handleSubmitButtonClicked = (e: Event | FormEvent) => {
        if (this.state.cancelRegisterToken) {
            this.setState({ cancelRegisterToken: false });
        }

        if (this.state.errorMessage && this.state.errorMessage.length > 0) {
            this.setState({ errorMessage: undefined });
        }

        window.LOGOUT_TIMER_REACT = addLogoutTimer();
        e.preventDefault();

        const data = { "isBusinessCodeError": false, "isUserIdError": false };
        data.isBusinessCodeError = (this.state.businessCodeUnmasked == null || this.state.businessCodeUnmasked === "" || !CitiRegEx.businessCode(this.state.businessCodeUnmasked));
        data.isUserIdError = (this.state.userIdUnmasked == null || this.state.userIdUnmasked === "" || !CitiRegEx.userCode(this.state.userIdUnmasked));
        this.setState({ businessCodeError: data.isBusinessCodeError ? 'Invalid Business Code' : undefined });
        this.setState({ userIdError: data.isUserIdError ? 'Invalid User ID' : undefined });

        if (data.isBusinessCodeError || data.isUserIdError) {
            return;
        }

        this.setState({ isLoading: true });
        this.context?.DEVICE.checkDevice(
            (checkDeviceResponse: any) => {
                if (checkDeviceResponse.wirelessProtocol === __constants.NO_NETWORK) {
                    this.setState({
                        errorMessage: __constants.ERROR_NO_NETWORK_CONNECTION,
                        isLoading: false
                    });
                }
                else {
                    this.handleRegisterToken1();
                }
            },
            (error: any) => {
                console.log(error);
            },
        );
    }

    handleRegisterToken1() {
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            const args = [{ "foo": "bar" }];
            this.context?.DEVICE.getAkamai((response: any) => {
                const akamaiData = _.get(response, 'akamaiData', "");
                this.handleRegisterToken2(akamaiData);
            }, (fail: any) => {
                console.log("AKAMAI DATA RETRIVAL FAILED");
            }, args);
        } else {
            this.handleRegisterToken2();
        }
    }

    handleRegisterToken2(akamaiData?: string) {
        if (this.state.isForgotPINWorkflow) {
            this.unenrollNotif((deviceInfo: { [key: string]: any }) => {
                this.handleRegisterToken3(akamaiData, deviceInfo);
            });

            return;
        }

        this.handleRegisterToken3(akamaiData);
    }

    handleRegisterToken3(akamaiData?: string, deviceInfo?: { [key: string]: any }) {
        console.log('handleRegisterToken...');

        this.context?.API.LOGIN_SERVICE.registerToken(this.state.businessCodeUnmasked, this.state.userIdUnmasked, this.state.password, "", deviceInfo, this.handleSuccessRegisterTokenResponse, akamaiData)
            .then(response => {
                if (response.status !== WAIT_FOR_IOS_CALLBACK)
                    this.handleSuccessRegisterTokenResponse(response.data)
                else
                    console.log('waiting for ios call', response)
            })
            .catch(error => {
                this.handleFailedRegisterTokenResponse(error);
            });
    }

    handleSuccessRegisterTokenResponse = (registerTokenResponse: RegisterTokenResponse) => {
        if (this.state.cancelRegisterToken) {
            return;
        }

        if (registerTokenResponse.success) {
            const userP86Id = registerTokenResponse.userP86;
            if (this.state.isForgotPINWorkflow) {
                const success = registerTokenResponse.unRegisterNotifSuccess;
                if (success) {
                    this.context?.DEVICE.setPushNotifEnroll({
                        enrolled: false,
                        enrolledToken: ''
                    });
                }

                this.unenrollBiometrics(() => {
                    this.navigateToVerificationCodePage(userP86Id, registerTokenResponse, success ? this.notifDeviceInfo : undefined);
                });
            } else {
                this.navigateToVerificationCodePage(userP86Id, registerTokenResponse);
            }
        } else {
            console.log("handleSuccessRegisterTokenResponse SUCCESS FALSE:");
            this.setState({
                businessCode: "",
                businessCodeUnmasked: "",
                userId: "",
                userIdUnmasked: "",
                password: "",
                errorMessage: registerTokenResponse.errors ? registerTokenResponse.errors[0].value : "",
                isLoading: false
            });
        }
    }

    handleFailedRegisterTokenResponse = (error: Object) => {
        console.log('RegisterToken on failed..');
        this.setState({
            isLoading: false
        });
    }

    disabledBtn = () => {
        return (this.state.businessCode && this.state.userId && this.state.password?.length > 0) ? false : true;
    };

    handleCancelButtonClicked = () => {
        this.setState({
            businessCode: "",
            businessCodeUnmasked: "",
            userId: "",
            userIdUnmasked: "",
            password: "",
            errorMessage: undefined,
            isLoading: false,
            cancelRegisterToken: true
        });
        this.context?.DEVICE.goToNativePage();
    }

    validateBusinessCode = (e: Event) => {
        const bCode: string = (e.target as any).value;

        if (CitiRegEx.onlyNumbers(bCode)) {
            this.setState({
                businessCode: bCode
            });
        }
    }

    validateUserId = (e: Event) => {
        const user_id: string = (e.target as any).value;
        if (CitiRegEx.onlyNumbers(user_id)) {
            this.setState({
                userIdUnmasked: user_id,
                userId: user_id
            });
        }
    }

    renderInputView(data: { [key: string]: any }) {
        return (
            <>
                {
                    data.show
                        ? <CBLInput
                            id={data.id}
                            label={data.label}
                            value={data.val}
                            key={data.label}
                            type={data.type}
                            placeholder={data.placeholder}
                            onChange={data.onChangeFunc}
                            onBlur={data.onBlur ? data.onBlur : null}
                            onFocus={data.onFocus ? data.onFocus : this.onInputRemoveErrorMessageFocus}
                            required={!data.optional}
                            autoFocus={data.autoFocus}
                            isBusinessCode={data.businessCode}
                            maxLength={data.maxLength ? data.maxLength : null}
                            numericInput={data.onlyNumbers}
                            disabled={data.disabled}
                        />
                        : null
                }
                {
                    data.enableForgotPasswordLink && !this.state.isRegister
                        ? <div className='link-end'>
                            <Button
                                color="link"
                                id="id-frgt-pwd-tip-mfa"
                                role={'link'}
                                disabled={this.state.isLoading}
                                onBlur={() => { this.setState({ showForgotPasswordTip: false }) }}
                                onClick={this.handleForgotPassword}>
                                {__constants.FORGOT_PASSWORD}
                            </Button>
                            <div
                                style={{ display: "flex" }}
                                tabIndex={0}
                                id='forgot-password-tooltip'
                                className='forgot-password-tooltip'
                                aria-label={this.state.showForgotPasswordTip ? __constants.FORGOT_PASSWORD_TIP : ''}
                            >
                                <Tooltip
                                    id="tooltip6"
                                    placement="left"
                                    aria-label={__constants.FORGOT_PASSWORD_TIP}
                                    overlay={
                                        <span
                                            onBlur={() => { this.setState({ showForgotPasswordTip: false }) }}
                                            onClick={() => { this.setState({ showForgotPasswordTip: false }) }}>
                                            {'To reset your password, please call \n'}
                                            <a href='tel:1-800-285-1709' style={{ color: '#FFF' }}>1-800-285-1709</a>
                                        </span>
                                    }
                                    trigger='click'
                                    visible={this.state.showForgotPasswordTip}
                                    overlayClassName='forgot-password-tip'
                                >
                                    {
                                        this.state.isForgotPINWorkflow ?
                                            <i
                                                role={'link'}
                                                aria-label={this.state.showForgotPasswordTip ? __constants.FORGOT_PASSWORD_TIP : __constants.FORGOT_PASSWORD}

                                                id="forgot-password-tip-icon"

                                                className="lmnicon lmnicon-question-circle-o lmn-btn-link forgot-password-tip-icon"
                                            />
                                            : null
                                    }
                                </Tooltip>
                            </div>
                        </div>
                        : null
                }
                {
                    data.error
                        ? <p className="error-message" key={`k${data.label}`}>
                            {data.error}
                        </p>
                        : null
                }
            </>
        );
    }

    renderHeader = () => {
        let header = this.state.isRegister ? __constants.REGISTER_A_TOKEN :
            (this.state.isForgotPINWorkflow ? __constants.FORGOT_PIN_TITLE : '');
        let subHeader = this.state.isRegister ? __constants.REGISTER_TOKEN_ENTER_CITI_INFO_TITLE : '';
        return (
            <LandingHeader
                context={this.context}
                title={header}
                subtitle={subHeader}
            />
        )
    }

    render() {
        return (
            <>
                {this.renderHeader()}
                <div className="react-container auto-overflow">
                    <div className="header-title-container">
                        {
                            this.state.errorMessage
                                ? <Alert type='error' content={this.state.errorMessage} />
                                : null
                        }
                    </div>
                    <form onSubmit={this.handleSubmitButtonClicked}>
                        {this.inputInfo().map((val) => {
                            return this.renderInputView(val);
                        })}

                        <div className="login-action-btn-container">

                            <Button
                                color="primary"
                                disabled={this.state.isLoading || this.disabledBtn()}
                                className='buttonWithMargin'
                                showLoading={this.state.isLoading}
                                onClick={this.handleSubmitButtonClicked}>
                                {
                                    this.state.isRegister ?
                                        __constants.REGISTER_TOKEN :
                                        this.state.isForgotPINWorkflow ?
                                            'Continue'
                                            : __constants.LOG_IN
                                }
                            </Button>

                            {
                                <Button
                                    onClick={this.handleCancelButtonClicked}
                                    color="ghost" className='buttonWithMargin'>{__constants.CANCEL}</Button>
                            }
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

MFALoginAll.contextType = ApplicationContext;