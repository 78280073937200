import { useContext, useEffect, useState } from 'react';
import { APP_LOCATIONS } from '../../routes/routes';
import { DEVICE_TYPE } from '../../device/xdevice';
import { Button } from '@citi-icg-172888/icgds-react';
import LandingHeader from '../header/header.component';
import ApplicationContext from '../../context/application-context';
import { getDeviceInfo } from '../../utils/mobile.common.util';
import { removeChildForBottomNav, compareVersion, focusToElementWithClassName } from '../../utils/common.util';
import { renderLoader } from '../common-ui/common-ui.components';
import _ from 'lodash';
import __constants from '../../common/constants'

interface Props {
    history: any;
}

const LandingPage = (props: Props) => {


    const context = useContext(ApplicationContext);


    let deviceCheckCount: number = 0;

    const [showManualReload, setShowManualReload] = useState(false);

    useEffect(() => {

        xcomponentDidMount();

        return () => {
            clearInterval(deviceCheckTimer);
        }

    }, [])


    useEffect(() => {
        if (!showManualReload) {
            //ADA | Focus on header on initial load
            focusToElementWithClassName('.header-content .header-text', 400);
        }
    }, [showManualReload]);


    const xcomponentDidMount = () => {
        window.GLOBAL_ROOT = window.location.origin + "/";

        removeChildForBottomNav();
        context?.Router?.__setHistory(props.history);

        if (window.Android) {
            window.Android_BAK = window.Android
        }

        initDeviceCheck();
        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB) {
            context?.Router.navigate(APP_LOCATIONS.SignOnPage);
            return;
        }
    }

    const initDeviceCheck = (interactive: boolean = false) => {
        getDeviceInfo(() => {
            clearInterval(deviceCheckTimer);
            fetchRedirections();
        })

        if (!interactive) {
            deviceCheckCount++;
            if (deviceCheckCount > 10) {
                setShowManualReload(true);
            }
        }
    }

    let deviceCheckTimer = setInterval(initDeviceCheck, 1500);


    const fetchRedirections = () => {
        if ((!compareVersion('16')) || (compareVersion('16') && window.THE_DEVICE_TYPE !== DEVICE_TYPE.ANDROID) || compareVersion('17')) {
            context?.DEVICE?.getRedirections((data: any) => {
                console.log('-------get redirection data--------', data.redirect);
                if (data.redirect === '/') {
                    context?.Router.navigate(APP_LOCATIONS.SignOnPage);
                    return;
                }
                if (data === undefined || data.redirect === undefined) {
                    if (window.DEVICE_INFO.registered === true) {
                        context?.Router?.navigate(APP_LOCATIONS.MfaHome);
                    } else {
                        context?.Router?.navigate(APP_LOCATIONS.RegisterTokenPage);
                    }
                }
                if (window.DEVICE_INFO.registered === true && window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
                    context?.Router?.navigate(APP_LOCATIONS.MfaHome);
                } else {
                    context?.Router.navigate(data.redirect);
                }
            }, (err: any) => {
                console.log('-----get redirection data error ------', err);
                if (window.DEVICE_INFO.registered === true) {
                    context?.Router?.navigate(APP_LOCATIONS.MfaHome);
                } else {
                    context?.Router.navigate(APP_LOCATIONS.SignOnPage);
                }
            })
        }
    }


    return (
        <>
            <LandingHeader title={__constants.WELCOME_1} context={context} />
            {
                showManualReload ?

                    <Button color={"primary"} style={{
                        color: '#dadada', width: '70%', marginLeft: 'auto',
                        marginRight: 'auto', padding: '5px', marginTop: '10%'
                    }} onClick={() => {
                        initDeviceCheck(true);
                        if (context?.DEVICE.isMobile()) {
                            fetchRedirections();
                        }
                    }}>Please Click to Refresh</Button>


                    : renderLoader('Loading..')
            }
        </>
    );


}

export default LandingPage;

LandingPage.contextType = ApplicationContext;