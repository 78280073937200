import _ from 'lodash';
import { ChangeEventHandler, ReactNode, useEffect } from 'react'
import { Radio, RadioGroup } from '@citi-icg-172888/icgds-react';

interface RadioGroupViewProps {
    parentClassName?: string,
    radioGroupName: string,
    radioGroupClassName?: string,
    disable?: boolean,
    inline?: boolean,
    options: any,
    customAriaLabels?: any,
    radioId?: string,
    defaultValue?: string,
    value?: string | { [key: string]: any },
    onChange: ChangeEventHandler,
    radioDisableCondition?: Function,
    radioChildren?: (val: any, index: any) => ReactNode;
}

const RadioGroupView = (p: RadioGroupViewProps) => {

    useEffect(() => {
        _setADALabelForRadioGroup();
    }, []);

    useEffect(() => {
        _setADALabelForRadioGroup();
    }, [p.value])

    function _setADALabelForRadioGroup() {
        const sortTypeRadioGroup: Element | null = document.querySelector(`.${p.parentClassName}`);
        if (sortTypeRadioGroup) {
            sortTypeRadioGroup.setAttribute('role', 'radiogroup');
            const sortTypeRadio1: Element | null = document.querySelector(`.${p.radioGroupName}-radio-0`)
            if (sortTypeRadio1) {
                sortTypeRadio1.setAttribute('role', 'radio');
                sortTypeRadio1.setAttribute('tabindex', '0')
                sortTypeRadio1.setAttribute('aria-checked', `${p.value === p.options[0] ? true : false}`)
                if (p.customAriaLabels) {
                    sortTypeRadio1.setAttribute('aria-label', `Radio button, ${p.customAriaLabels[0] ? ' Checked' : ' Not Checked'}, In group, Option 1 of 2 `)
                } else {
                    sortTypeRadio1.setAttribute('aria-label', `Radio button, ${p.options[0]} ${p.value === p.options[0] ? ' Checked' : ' Not Checked'}, In group, Option 1 of 2 `)
                }
            }
            const sortTypeRadio2: Element | null = document.querySelector(`.${p.radioGroupName}-radio-1`)
            if (sortTypeRadio2) {
                sortTypeRadio2.setAttribute('role', 'radio');
                sortTypeRadio2.setAttribute('tabindex', '0')
                sortTypeRadio2.setAttribute('aria-checked', `${p.value === p.options[1] ? true : false}`)
                if (p.customAriaLabels) {
                    sortTypeRadio2.setAttribute('aria-label', `Radio button, ${p.customAriaLabels[1] ? ' Checked' : ' Not Checked'}, In group, Option 2 of 2`)
                } else {
                    sortTypeRadio2.setAttribute('aria-label', `Radio button, ${p.options[1]} ${p.value === p.options[1] ? ' Checked' : ' Not Checked'}, In group, Option 2 of 2`)
                }
            }
        }
    }

    /**
     * Render the picker control
     * @returns 
     */
    const createUI = () => {

        return <RadioGroup
            disabled={p.disable}
            name={p.radioGroupName}
            className={p?.radioGroupClassName}
            defaultValue={p.defaultValue}
            value={p.value}
            onChange={p.onChange}
        >
            {
                _.map(p.options, (val, key) => {
                    return <Radio
                        inline={p.inline}
                        tabIndex={0}
                        id={p.radioId}
                        className={`${p.radioGroupName}-radio-${key}`}
                        key={key}
                        value={typeof val === 'string' ? val : (typeof val === 'object' && val !== null ? JSON.stringify(val) : '')}
                        disabled={p.radioDisableCondition && p.radioDisableCondition(val)}
                    >
                        {p.radioChildren ? p.radioChildren(val, key) : null}
                    </Radio>
                })}
        </RadioGroup>

    }

    return createUI();

}


export default RadioGroupView;