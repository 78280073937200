import React from "react";
import _ from 'lodash';
import './text.style.scss';
import { maskMiddleText } from "../../utils/mask.util";
import { DEVICE_TYPE } from "../../device/xdevice";
import { Icon, Tooltip } from "@citi-icg-172888/icgds-react";
import Button from "../button/button";


interface Props {
    type?: string,
    label?: string
    required?: boolean
    showHiddenIcon?: boolean
    showSearchIcon?: boolean
    showSearchIconRight?: boolean
    value?: string
    placeholder?: string
    onChange: any
    onBlur?: any
    onFocus?: any,
    onKeyDown?: React.KeyboardEventHandler,
    onKeyUp?: React.KeyboardEventHandler,
    disabled?: boolean,
    maxLength?: number,
    max?: number,
    autoFocus?: boolean,
    isBusinessCode?: boolean
    mask?: boolean,
    numericInput?: boolean,
    prefix?: string,
    suffix?: string,
    wrapperClass?: string
    bodyClass?: string
    ariaLabel?: string
    id?: string,
    onSuffixClick?: Function,
    ariaLabelSuffix?: string,
    inputClass?: string,
    suffixAriaRole?: string,
    labelToolTip?: string,
    disableSuffix?: boolean
}

interface State {
    hidden?: boolean
    businessCodePrefix?: string
    unmaskedValue: string
    focusLost?: boolean,
    visibleTooltip?: boolean
}

export default class CBOLText extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            hidden: true,
            businessCodePrefix: '7000-0000-',
            unmaskedValue: props.value ? props.value : '',
            focusLost: !props.autoFocus,
            visibleTooltip: false
        };
        if (props.value) {
            this.handleValueChange({ target: { value: this.state.unmaskedValue } })
        }
        this.toggleShow = this.toggleShow.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

    }

    handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            this.setState({ visibleTooltip: false });
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }


    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }


    handleFocus = (e: any) => {
        this.setState({
            businessCodePrefix: '7000-0000-',
            focusLost: false
        });
        if (_.isFunction(this.props.onFocus)) {
            this.props.onFocus(e);
        }
        if (this.props.mask) console.log('focus gained..');
    }

    handleBlur = (e: any) => {
        this.setState({
            focusLost: true
        });
        if (_.isFunction(this.props.onBlur)) {
            if (!this.props.value?.includes('*')) {
                this.props.onBlur(e);
            }
        }
    }

    handleValueChange = (e: any) => {
        const cVal: string = (e.target as any).value;
        this.setState({
            unmaskedValue: cVal
        });
        if (_.isFunction(this.props.onChange)) {
            this.props.onChange(e);
            /*if (!cVal.includes('*')) {
                this.props.onChange(e);
            }*/
        }
    }

    generateInputId() {
        if (this.props.id) {
            return this.props.id;
        }

        const customId = 'input-' + _.get(this.props, 'type', 'text') + '-' + window.location.hash + '-' + _.get(this.props, 'label', 'default');
        return customId.replace(/#|\//g, '').replace(/ /g, '-').toLocaleLowerCase();
    }

    render() {
        return (
            <>
                <div className={`cbusol-input-group ${this.props.wrapperClass} cbip-${this.props.id ? this.props.id : ''}`}>

                    {
                        this.props.label ?
                            <label htmlFor={this.generateInputId()} className="input-label" aria-label={`${this.props.ariaLabel ? this.props.ariaLabel : this.props.label}${this.props.required ? ', Required' : ''} `}>
                                {
                                    this.props.required ?
                                        <span aria-hidden="true" style={{ color: '#B60000' }}>
                                            {`* `}
                                        </span>
                                        : null
                                }
                                <span aria-hidden="true">{this.props.label}</span>
                                {this.props.labelToolTip ? <Tooltip
                                    id="tooltip1"
                                    placement="right"
                                    data-cv-tracking-id="tooltip-tracking-id"
                                    overlay={this.props.labelToolTip}
                                    trigger={['hover', 'click', 'focus']}
                                    overlayClassName="input-label-tooltip"
                                    visible={this.state.visibleTooltip}
                                    onVisibleChange={(visible) => this.setState({ visibleTooltip: visible })}
                                >
                                    <Icon type="info-circle-o" tabIndex={0} aria-label={'tip: ' + this.props.labelToolTip} />
                                </Tooltip> : null}

                            </label> :
                            null
                    }
                    <div className={`cbip-group ${this.props.bodyClass}`}>

                        {
                            this.props.isBusinessCode ?
                                <>
                                    <div className="businesslabel-container" >
                                        <span className="business-label" style={{ color: `${this.props.disabled ? '#676E74' : ''}` }}>
                                            {
                                                this.state.businessCodePrefix
                                            }
                                        </span>
                                    </div>
                                </>
                                : null
                        }

                        {
                            _.isString(this.props.prefix) && this.props.prefix.length > 0 ?
                                <>
                                    <div className="businesslabel-container">
                                        <span className="business-label" style={{ color: `${this.props.disabled ? '#676E74' : ''}` }}>
                                            {
                                                this.props.prefix
                                            }
                                        </span>
                                    </div>
                                </>
                                : null
                        }

                        <>
                            {
                                this.props.showSearchIcon ?
                                    <i role="img" tabIndex={0} aria-label="Search Icon" className="lmnicon lmnicon-search"></i> : null
                            }
                        </>
                        <div>
                            <input
                                tabIndex={0}
                                id={this.generateInputId()}
                                aria-required={this.props.required !== undefined && this.props.required}
                                aria-label={`${this.props.ariaLabel ? this.props.ariaLabel : this.props.label}${window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID && this.props.required ? ', Required' : ''} `}
                                className={`cbusol-input${this.props.isBusinessCode ? ' business-code-input' : ''}${this.props.disabled ? ' disabled' : ''} ${this.props.inputClass ? this.props.inputClass : ''}`}
                                type={(this.props.isBusinessCode || this.props.numericInput) ? 'tel' : (this.props.type ? this.props.type : 'text')}
                                onChange={this.handleValueChange}
                                onBlur={this.handleBlur}
                                onFocus={this.handleFocus}
                                onKeyDown={this.props.onKeyDown}
                                onKeyUp={this.props.onKeyUp}
                                value={
                                    this.props.showHiddenIcon ?
                                        this.state.hidden ?
                                            maskMiddleText(this.props.value ? this.props.value : '')
                                            : this.props.value
                                        : this.props.mask ?
                                            this.state.focusLost ? maskMiddleText(this.props.value ? this.props.value : '') : this.props.value
                                            : this.props.value
                                }
                                //placeholder={this.props.mask ? this.state.focusLost ? maskMiddleText(this.state.unmaskedValue) : this.props.value : this.props.value}
                                disabled={this.props.disabled}
                                readOnly={this.props.disabled}
                                maxLength={this.props.maxLength}
                                max={this.props.max}
                                autoFocus={this.props.autoFocus}
                                placeholder={this.props.placeholder}
                            />
                        </div>
                        <>
                            {
                                this.props.showSearchIconRight ?
                                    <i role="img" tabIndex={0} aria-label="Search Icon" className="lmnicon lmnicon-search"></i> : null
                            }
                        </>
                        {
                            !this.props.disableSuffix && _.isString(this.props.suffix) && this.props.suffix.length > 0 ?
                                <>
                                    <div className={`businesslabel-container ${this.props.suffix === 'lmnicon lmnicon-close' ? 'suffix-pointer' : ''} `}
                                        role={this.props.suffixAriaRole}
                                        tabIndex={0}
                                        aria-label={this.props.ariaLabelSuffix}
                                        onClick={() => {
                                            if (this.props.onSuffixClick) {
                                                this.props.onSuffixClick()
                                            }
                                        }}>
                                        <Button className="business-label suffix" style={{ color: `${this.props.disabled ? '#676E74' : ''}` }}>
                                            {
                                                this.props.suffix.includes('lmnicon') ?
                                                    <i aria-hidden={true} className={this.props.suffix} style={{ marginLeft: '-0.45rem', marginRight: '0' }}></i> :
                                                    this.props.suffix
                                            }
                                        </Button>
                                    </div>
                                </>
                                : null
                        }
                        <>
                            {
                                this.props.showHiddenIcon ?
                                    <i role="img" aria-label={`${(this.state.hidden) ? `View ${(this.props.label)}` : `Hide ${(this.props.label)}`}, double-tap to activate`}
                                        className={`input-label-icon lmnicon ${(this.state.hidden) ? 'lmnicon-view-disabled' : 'lmnicon-view'}`}
                                        onClick={this.toggleShow}
                                    ></i> :
                                    null
                            }
                        </>

                    </div>
                </div>

            </>

        );
    }
}