import { useEffect, useContext } from "react";
import { Switch, Drawer } from "@citi-icg-172888/icgds-react";
import {
  RAccordion,
  AccordionItemContent,
} from "../../ui-kit/accordion/accordion.component";
import _ from "../../lodash";
import __constants from "../../common/constants";
import "./left-menu.style.scss";
import { APP_LOCATIONS } from "../../routes/routes";
import { DEVICE_TYPE } from "../../device/xdevice";
import { ReactComponent as Menu } from "../../assets/images/menu_blue.svg";
import { compareVersion } from "../../utils/common.util";
import ApplicationContext from "../../context/application-context";

interface MenuProps {
  visible: boolean;
  /**
   * example
   * [
   *  {
   *    title: 'Mobile token management',
   *    href: '/mobileTokenManagementSummaryPage'
   *   },{
   *    title: 'System Administration',
   *    href: '/adminFunctionMenuPage',
   *    subMenus: [
   *      {
   *        title: 'Issue New Password',
   *        href: 'listOfUser/issueNewPassword/userId'
   *      },
   *      {
   *        title: 'System Admin Approvals',
   *        href: 'makerCheckerApprovalsPage'
   *      }
   *   ]
   *   }
   * ]
   */
  menus?: Array<{ [key: string]: string }>;
  footer?: React.ReactNode;
  showBiometricsSwitch?: boolean;
  biometricsChecked?: boolean;
  showNotifSwitch?: boolean;
  close: Function;
  handleMenuClick?: Function;
  handleBiometricsChecked?: Function;
  handleNotifChecked?: Function;
}

const LeftMenu = (props: MenuProps) => {
  const {
    visible,
    menus,
    footer,
    showBiometricsSwitch,
    biometricsChecked,
    showNotifSwitch,
    close,
    handleMenuClick,
    handleBiometricsChecked,
    handleNotifChecked
  } = props;

  const context = useContext(ApplicationContext);
  let notifChecked = context?.Security.getNotificationChecked();

  const defaultMenus = () => {
    const menus: any = [
      { title: __constants.CONTACT_US, href: APP_LOCATIONS.ContactUs },
      { title: __constants.FAQS, href: APP_LOCATIONS.Faqs },
      {
        title: __constants.USER_AGREEMENT,
        href: APP_LOCATIONS.OnlineUserAgreement,
      },
      { title: __constants.PRIVACY_STATEMENT, href: APP_LOCATIONS.Privacy },
      { title: __constants.COOKIE_POLICY, href: APP_LOCATIONS.CookiePolicy },
    ];

    if (
      window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS ||
      window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID
    ) {
      menus.splice(3, 0, {
        title: __constants.TERM_OF_USE,
        href: APP_LOCATIONS.TermsOfUse,
      });
    }

    if (
      window.OneTrust !== undefined && window.OneTrust?.GetDomainData() !== undefined &&
      (compareVersion("17") || window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB)
    ) {
      if (window.OneTrust.GetDomainData().ShowAlertNotice === true) {
        const manageCookiePreferences = {
          title: __constants.MANAGE_COOKIE_PREFERENCES,
          href: "manageCookiePreferences",
        };
        menus.push(manageCookiePreferences);
      }

    }

    return menus;
  };

  useEffect(() => {
    const wrappers = document.getElementsByClassName(
      "lmn-drawer-content-wrapper"
    );
    for (let e of wrappers) {
      e.setAttribute("role", "dialog");
      e.setAttribute("aria-modal", "true");
      e.setAttribute("aria-live", "assertive");
    }
  }, []);

  const onClose = () => close();

  function _handleMenuClick(href: string) {
    if (_.isFunction(handleMenuClick)) {
      handleMenuClick(href);
    }
  }

  function _handleBiometricsCheckd(checked: boolean) {
    onClose();

    if (_.isFunction(handleBiometricsChecked)) {
      handleBiometricsChecked(checked);
    }
  }

  function _handleNotifCheckd(checked: boolean) {
    onClose();

    if (_.isFunction(handleNotifChecked)) {
      handleNotifChecked(checked);
    }
  }

  function generateSubMenuContent(menu: { [key: string]: any }) {
    return <div onClick={() => _handleMenuClick(menu.href)}>{menu.title}</div>;
  }

  function generateSubMenus(
    header: string,
    menus: Array<{ [key: string]: any }>
  ) {
    return [
      {
        header,
        content: _.map(menus, (menu) => {
          return generateSubMenuContent(menu);
        }),
      },
    ];
  }

  function _renderMenus(
    menus?: Array<{ [key: string]: any }>,
    showBiometricsSwitch?: boolean,
    showNotifSwitch?: boolean
  ) {
    return (
      <ul className="left-menu">
        {_.map(menus, (val) => {
          const subMenus = val.subMenus;
          if (_.isArray(subMenus) && subMenus.length > 0) {
            return (
              <li key={val.title} className="left-menu-item" tabIndex={0}>
                <RAccordion
                  content={
                    generateSubMenus(
                      val.title,
                      subMenus
                    ) as Array<AccordionItemContent>
                  }
                />
              </li>
            );
          }

          return (
            <li
              tabIndex={0}
              role="button"
              aria-label={val.title + ", button"}
              key={val.title}
              className="left-menu-item"
              onClick={() => {
                _handleMenuClick(val.href);
              }}
              onKeyDown={(e) => e.key === "Enter" && _handleMenuClick(val.href)}
            >
              {/* ADA-C41630-17712 */}
              <a className="left-menu-content">{val.title}</a>
            </li>
          );
        })}

        {showBiometricsSwitch ? (
          <li
            className="left-menu-item left-menu-item-switch"
            tabIndex={0}
            role="switch"
            aria-checked={biometricsChecked}
            aria-label={__constants.BIOMETRIC_LOGIN}
            onClick={() => {
              _handleBiometricsCheckd(!biometricsChecked);
            }}
          >
            <span className="left-menu-content">{__constants.BIOMETRIC_LOGIN}</span>
            <Switch checked={biometricsChecked} />
          </li>
        ) : null}

        {showNotifSwitch ? (
          <li
            className="left-menu-item left-menu-item-switch"
            tabIndex={0}
            role="switch"
            aria-checked={notifChecked}
            aria-label={__constants.PUSH_NOTIF_HEADER}
            onClick={() => {
              _handleNotifCheckd(!notifChecked);
            }}
          >
            <span className="left-menu-content">{__constants.PUSH_NOTIF_HEADER}</span>
            <Switch checked={notifChecked} />
          </li>
        ) : null}
      </ul>
    );
  }

  return (
    <div>
      <Drawer
        width={270}
        visible={visible}
        closable={false}
        onClose={onClose}
        footer={footer}
      >
        <div tabIndex={0} className="menu-icon-style">
          <Menu
            role="img"
            aria-label="Navigation Menu, expanded, double-tap to collapse"
            onClick={onClose}
          />
        </div>

        {
          _renderMenus(menus, showBiometricsSwitch, showNotifSwitch)
        }
        {
          (_.isArray(menus) && menus.length > 0) || showBiometricsSwitch || showNotifSwitch
            ? <div className="left-menu-division" />
            : null
        }
        {
          _renderMenus(defaultMenus())
        }
      </Drawer>
    </div>
  );
};

export default LeftMenu;
