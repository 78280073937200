import { isValidElement, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import Button from '../../../ui-kit/button/button';
import { TMXChallengePhoneOTP, TMXChallengeEmailOTP } from '../../pre-signon.chunk.index';
import __constants from '../../../common/constants';
import ApplicationContext from '../../../context/application-context';
import { DEVICE_TYPE } from '../../../device/xdevice';
import { initializeTMX, updateRegistrationResult } from './biometrics.util';
import faceIcon from '../../../assets/images/face_id.svg';
import fingerprintIcon from '../../../assets/images/fingerprint.png';
import './biometrics.style.scss';
import { renderSessionSuspended } from '../../common-ui/common-ui.components';
import { logOut } from '../../../utils/common.util';
import CustomModal from '../../../ui-kit/custom-modal/custom-modal.component';

interface Props {
    onClose?: Function;
}

function BiometricsBindView(props: Props) {
    const context = useContext(ApplicationContext);
    const biometricsInfo = context?.Security.getBiometricsInfo();
    const defaultContent = (biometricsInfo?.supportedBiometricID.includes(__constants.BIOMETRICS_ID.FINGERPRINT))
        ? __constants.BIOMETRICS_ENROLL.CONTENT.replace(/Face ID/g, window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS ? __constants.BIOMETRICS_ID.TOUCH_ID : __constants.BIOMETRICS_ID.FINGERPRINT)
        : __constants.BIOMETRICS_ENROLL.CONTENT;

    const [showModal, setShowModal] = useState(true);
    const [showSessionSuspended, setShowSessionSuspended] = useState(false);

    const [title, setTitle] = useState(__constants.BIOMETRICS_ENROLL.TITLE);
    const [content, setContent] = useState(defaultContent);
    const [footer, setFooter] = useState<string>(__constants.BIOMETRICS_FOOTER.ENROLL);

    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        context?.DEVICE.changeBioCatchContext(__constants.BIOMETRICS_CONTEXT.ENROLL);

        if (context.Security?.isBiometricsDown()) {
            setContent(__constants.BIOMETRICS_OFFLINE.ENROLL);
            setFooter(__constants.OK);
        }
    }, []);

    function _onClose() {
        setShowModal(false);

        if (_.isFunction(props.onClose)) {
            props.onClose();
        }
    }

    function _onTMXChallengeVerifyDone() {
        setShowLoading(true);
        setTitle(__constants.BIOMETRICS_ENROLL.TITLE);
        setContent(defaultContent);
        setFooter(__constants.BIOMETRICS_FOOTER.ENROLL);

        _sendEnollReq();
    }

    function _enrollBiometryIdFailed(challengeType?: string) {
        setShowLoading(false);

        let content: any = __constants.BIOMETRICS_ENROLL.ERROR_CONTENT;
        if (challengeType) {
            if (challengeType === __constants.BIOMETRICS_CHALLENGE_TYPE.EMAIL) {
                content = <TMXChallengeEmailOTP onDone={_onTMXChallengeVerifyDone} onCanceled={_onClose} />;
            } else if (challengeType === __constants.BIOMETRICS_CHALLENGE_TYPE.PHONE) {
                content = <TMXChallengePhoneOTP onDone={_onTMXChallengeVerifyDone} onCanceled={_onClose} />;
            } else if (challengeType === __constants.TMX_CHALLENGE_SESSION_SUSPENDED) {
                setShowSessionSuspended(true);
            }
        } else {
            updateRegistrationResult(false);
        }

        setContent(content);
        setFooter(__constants.OK);

        context?.Security.setBiometricsInfo({ ...biometricsInfo, ...{ setUpBiometrics: false } });
    }

    function _sendEnollReq() {

        context?.DEVICE.bindBiometryId((resp: any) => {
            console.log('Transmit - Biometric - bindBiometryId - succeed - ', resp);
            if (_.get(resp, 'success', false)) {
                setShowLoading(false);

                setTitle(__constants.BIOMETRICS_ENROLL.SUCCESS_TITLE);
                setContent(__constants.BIOMETRICS_ENROLL.SUCCESS_CONTENT);
                setFooter(__constants.OK);

                context?.Security.setBiometricsInfo({ ...biometricsInfo, ...{ policy: __constants.BIOMETRICS_POLICY.LOGIN } });

                updateRegistrationResult(true);
                return;
            }

            _enrollBiometryIdFailed();
        }, (error: any) => {
            console.log('Transmit - Biometric - bindBiometryId - failed ', error);

            _enrollBiometryIdFailed();
        });
    }

    function _handleFooterBtnClicked() {
        if (footer && footer === __constants.BIOMETRICS_FOOTER.ENROLL) {
            setShowLoading(true);

            initializeTMX(__constants.BIOMETRICS_EVENT_TYPE.ENROLL, false, _sendEnollReq, _enrollBiometryIdFailed);
            return;
        }

        _onClose();
    }

    function _renderFooter() {
        if (isValidElement(content)) {
            return null;
        }

        return <>
            {
                footer === __constants.BIOMETRICS_FOOTER.ENROLL
                    ? <Button
                        id={`btn-biometric-bind-${__constants.CANCEL}`}
                        color='secondary'
                        disabled={showLoading}
                        onClick={() => {
                            context?.Security.setBiometricsInfo({ ...biometricsInfo, ...{ setUpBiometrics: false } });
                            _onClose()
                        }}>
                        {__constants.CANCEL}
                    </Button>
                    : null
            }
            <Button
                id={`btn-biometric-bind-${footer}`}
                color='primary'
                disabled={showLoading}
                showLoading={showLoading}
                onClick={_handleFooterBtnClicked}>
                {footer}
            </Button>
            {showSessionSuspended ? renderSessionSuspended(logOut, false) : null}
        </>
    }

    function _renderTitle() {
        if (isValidElement(content)) {
            return null;
        }

        return <div className='biometrics-header-with-img'>
            {
                title !== __constants.BIOMETRICS_ENROLL.SUCCESS_TITLE
                    ? <img
                        aria-hidden='true'
                        alt=''
                        src={biometricsInfo?.supportedBiometricID.includes(__constants.BIOMETRICS_ID.FINGERPRINT) ? fingerprintIcon : faceIcon}
                    />
                    : null
            }
            <p>{title}</p>
        </div>
    }


    return (
        <CustomModal
            showModal={showModal}
            isCloseable={false}
            wrapperClass='biometrics-modal biometrics-custom-title'
            title={_renderTitle()}
            renderFooter={_renderFooter}
            body={
                isValidElement(content)
                    ? content
                    : <span className='biometrics-content'>{content}</span>
            }
            bodyStyle={{ maxHeight: 'none' }}   
        />
    )
}

export default BiometricsBindView;