import { useEffect, useState } from "react";
import { Modal } from "@citi-icg-172888/icgds-react";
import Button from "../button/button";
import __constants from "../../common/constants";
import "./custom-modal.style.scss";
import _ from "lodash";
import useEscapeKey from "../../utils/useEscapeKey";

interface CustomModalProps {
    userName?: string;
    title?: string;
    description?: string;
    descriptionArray?: string[];
    show: boolean;
    onContinue?: Function;
    continueButtoTitle?: string;
    onCancel?: Function;
    cancelButtoTitle?: string;
    showLoading?: boolean;
    cancellable?: boolean;
    onClose?: Function;
    isCloseable?: boolean;
}

const CustomModal = (p: CustomModalProps) => {
    const [isCancellable, setIsCancellable] = useState(true);

    useEffect(() => {
        setIsCancellable(p.cancellable !== undefined ? p.cancellable : true);
    }, [p.cancellable]);

    /**
     * ADA Implementation to focus on the header of the modal when it is opened
     */
    useEffect(() => {
        if (p.show) {
            _.delay(() => {
                const header: HTMLElement | null = document.querySelector(
                    ".custom-modal-container .lmn-modal .lmn-modal-content .lmn-modal-header .title-container"
                );
                if (header) {
                    header.setAttribute("tabIndex", "0");
                    header.focus();
                }
            }, 400);
        }
        const ele: Element | null = document.querySelector("#cbusol-view");
        if (ele) {
            if (p.show) {
                ele.setAttribute("aria-hidden", "true");
            }
        }

        return () => {
            if (ele) {
                ele.removeAttribute("aria-hidden");
            }
        };
    }, [p.show]);

    /**
     * Close the modal on escape key press
     */
    useEscapeKey(() => {
        if (p.onClose) p.onClose();
    });

    const onContinueClick = () => {
        if (p.onContinue) p.onContinue();
    };

    const onCancelClick = () => {
        if (p.onCancel) p.onCancel();
    };

    const onCloseClick = () => {
        if (p.onClose) p.onClose();
    };

    const renderTitle = () => {
        return (
            <>
                <div
                    className="title-container"
                    tabIndex={0}
                    aria-label={`${p.userName !== undefined && p.userName.length > 0 ? p.userName : ""
                        } ${p.title !== undefined && p.title.length > 0 ? p.title : ""}`}
                >
                    {p.userName !== undefined && p.userName.length > 0 ? (
                        <span aria-hidden="true">
                            {p.userName.length > 0 ? p.userName : null}
                        </span>
                    ) : null}

                    <p
                        aria-hidden="true"
                        className={`text-medium custom-modal-text ${p.description !== undefined || p.descriptionArray !== undefined
                                ? "bold"
                                : ""
                            }`}
                    >
                        {p.title}
                    </p>

                    {p.description !== undefined && p.description !== null ? (
                        <p
                            tabIndex={0}
                            className="text-small custom-modal-description"
                            dangerouslySetInnerHTML={{ __html: p.description }}
                        />
                    ) : null}
                </div>
            </>
        );
    };

    const uiRenderFooter = () => {
        return (
            <div className="footer-wrap">
                <div className="footer-button-wrap">
                    {isCancellable && (
                        <Button
                            outline
                            disabled={p.showLoading}
                            style={{ color: "#0066b3", borderColor: "#0066b3" }}
                            onClick={onCancelClick}
                        >
                            {p.cancelButtoTitle ? p.cancelButtoTitle : __constants.CANCEL}
                        </Button>
                    )}
                    <Button
                        color={"primary"}
                        disabled={p.showLoading}
                        onClick={onContinueClick}
                        showLoading={p.showLoading}
                    >
                        {p.continueButtoTitle ? p.continueButtoTitle : __constants.CONTINUE}
                    </Button>
                </div>
            </div>
        );
    };

    const ui = () => {
        return (
            <Modal
                centered
                visible={p.show}
                width="90%"
                wrapperClass="custom-modal-container"
                title={renderTitle()}
                closable={p.isCloseable}
                onClose={onCloseClick}
                footer={uiRenderFooter()}
            ></Modal>
        );
    };

    return ui();
};

export default CustomModal;
