import _ from 'lodash';
import __constants from '../common/constants';
import deviceCtx, { DEVICE_TYPE } from '../device/xdevice';
import securityCtx from '../context/security-context';
import { compareVersion } from '../utils/common.util';

let successCallback: Function | undefined = undefined;

const getMobileTokenProvisionedSuccess = (a: any) => {
    console.log("getMobileTokenProvisionedSuccess success...");
    console.log("getMobileTokenProvisionedSuccess appVersion:::" + a.appVersion);

    const mobileScreenResolution = window.screen.width + "X" + window.screen.height;
    window.DEVICE_INFO = {
        ...window.DEVICE_INFO, ...a, ...{
            version: __constants.APP_VERSION,
            mobileScreenResolution,
            registered: a.success ? true : false
        }
    }

    if (!window.DEVICE_INFO_BAK)
        window.DEVICE_INFO_BAK = { ...a }

    getInfo();
}

const getMobileTokenProvisionedFailure = (b: any) => {
    console.log("getMobileTokenProvisionedSuccess failure....");
    console.log("getMobileTokenProvisionedSuccess appVersion:::" + b.appVersion);

    const mobileScreenResolution = window.screen.width + "X" + window.screen.height;
    window.DEVICE_INFO = {
        ...window.DEVICE_INFO, ...b, ...{
            version: __constants.APP_VERSION,
            mobileScreenResolution
        }
    }

    getInfo();
}

const updateSSL = () => {
    deviceCtx.updateSSL(
        {},
        {},
        [{ "sslCert": "w9I8WrpHr1YAS0DugvLGsrI9Vm1yEkZAyllKxemXXf4=" }]
    );
}

const getInfo = () => {
    deviceCtx?.getInfo((data: any) => {
        window.p86Cache = data;
        console.log('---device info from landing----');
        if (data.platform === 'Android') {
            console.log("data.platform === 'Android'");
            window.THE_DEVICE_TYPE = DEVICE_TYPE.ANDROID
            window.DEVICE_INFO.mobileOs = __constants.ANDROID
            window.GLOBAL_ROOT = window.location.origin + "/";
            deviceCtx.setAndroid(true);
            successCallback && successCallback();
        }
        else if (data.platform === 'iOS') {
            console.log("data.platform === 'iOS'");
            window.THE_DEVICE_TYPE = DEVICE_TYPE.IOS;
            window.DEVICE_INFO.mobileOs = __constants.IOS;
            console.log('window.GLOBAL_ROOT  ' + window.GLOBAL_ROOT)
            window.GLOBAL_ROOT = window.location.origin + "/";
            successCallback && successCallback();
        }
        else {
            console.log("data.platform === ", data.platform);
            retryGetInfoIfNeeded();
        }
        updateSSL();
    }, (data: any) => {
        window.p86Cache = {}
        console.log('---getInfo fail----');
        if (data.platform === 'iOS') {
            console.log('---getInfo fail---- set THE_DEVICE_TYPE - ');
            window.THE_DEVICE_TYPE = DEVICE_TYPE.IOS;
            window.p86Cache = data;
            console.log('window.GLOBAL_ROOT  ' + window.GLOBAL_ROOT)
            window.DEVICE_INFO.mobileOs = __constants.IOS
            if (data.version !== undefined) {
                window.DEVICE_INFO.oSVersion = data.version
            }
            successCallback && successCallback();
        } else if (data.platform === 'Android') {
            window.p86Cache = data;
            window.THE_DEVICE_TYPE = DEVICE_TYPE.ANDROID;
            console.log('window.GLOBAL_ROOT  ' + window.GLOBAL_ROOT)
            window.DEVICE_INFO.mobileOs = __constants.ANDROID
            deviceCtx.setAndroid(true);
            if (data.version !== undefined) {
                window.DEVICE_INFO.oSVersion = data.version
            }
            successCallback && successCallback();
        } else {
            console.log("data.platform === ", data.platform);
            retryGetInfoIfNeeded();
        }
        updateSSL();
    })
}

const retryGetInfoIfNeeded = () => {
    // improvement for don't navigate to login card page when click Mobile App tab occasionally. It because don't get correct device info from native.
    // the root cause is the native method don't have unique callback name, the privious callback will be overwrite by next one on 15.x app.
    // this method can be removed after 15.x app is retired .

    if (compareVersion('16')) {
        return;
    }

    const attemptsNum = securityCtx?.getAttemptsNumLeftForGetInfo();
    if (_.isNumber(attemptsNum) && attemptsNum > 0) {
        securityCtx?.setAttemptsNumLeftForGetInfo(attemptsNum - 1);
        getInfo();
    }
}

export const getDeviceInfo = (success?: Function) => {
    securityCtx?.setTriggeredGetDeviceInfo(true);

    successCallback = success;
    deviceCtx?.mobileTokenProvisioned(
        getMobileTokenProvisionedSuccess,
        getMobileTokenProvisionedFailure,
        [{}]
    );
}

/**
 * This method is to receive an otp that is sent from Android
 * @param otp 
 */
export const setOTP = (otp?: string) => {
    console.log("Calling setOTP", otp);
    deviceCtx?.startSmsListener();
    window.ReactAndroidSuccessCallBack({
        'otp': otp
    })
}