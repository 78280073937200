import LandingHeader from '../header/header.component';
import __constants from '../../common/constants';
import { useEffect } from 'react';
import _ from 'lodash';
import { focusToElementWithClassName } from '../../utils/common.util';


function SessionSuspendedPage() {


    useEffect(() => {
        //ADA | focus on header on first page load
        focusToElementWithClassName('.header-content .header-text', 400);
    }, []);

    return (
        <>
            <LandingHeader title={__constants.SESSION_SUSPENDED} />
            <div className='react-container top-40 auto-overflow' tabIndex={0}>
                {
                    <p className='text-medium'>{window.isSessionSuspendedForLogin ? __constants.SESSION_SUSPENDED_INFORMATION_LOGIN
                        : window.isSessionSuspendedForWireSetup ? __constants.SESSION_SUSPENDED_INFORMATION_WIRE_SETUP
                            : __constants.SESSION_SUSPENDED_INFORMATION}</p>
                }
            </div>
        </>
    );
}

export default SessionSuspendedPage;