import _ from 'lodash';
import http from './api.core';
import { RequestMethod } from './api.core';
import __constants from '../common/constants';
import kEVENT_TRACKING from "../common/constants/EVENT_TRACKING";
import kGENERAL from "../common/constants/GENERAL";
import { AxiosResponse } from 'axios';
import securityCtx from '../context/security-context';
import { DEVICE_TYPE } from '../device/xdevice';
import { getUserAgent1 } from '../utils/api.util';
let MOCKED_API: any

export interface BiometricResponse {

}

export class BiometricApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === 'production');
        const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.ts').default
        // const m = require(isProd ? './mocks/prod.mock.ts' : './mocks/mocks.copy.ts').default
        MOCKED_API = m
    }

    initBiometricTMX(eventType: string, isForgotPINFlow: boolean, callBack?: Function, akamaiData?: string) {
        const params = {
            eventType: eventType  // enroll/unenroll
        }

        let headers = null;
        if (!isForgotPINFlow && window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                'x-acf-sensor-data': akamaiData,
                'user-agent1': getUserAgent1()
            }
        }

        const pattern = isForgotPINFlow ? 'forgotPIN/initBiometricTMX' : 'initBiometricTMX'
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}${pattern}/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            params,
            callBack,
            kEVENT_TRACKING.ChannelEnum.BIOMETRICS,
            pattern,
            MOCKED_API?.TRANSMIT?.TMX) as Promise<AxiosResponse<BiometricResponse>>;
    }

    checkBiometricAuthentication(eventType: string, isForgotPINFlow: boolean, businessCode?: string, userId?: string, callBack?: Function, akamaiData?: string) {
        let params = {
            eventType: eventType,  // enroll/unenroll,
            biometricId: (securityCtx?.getBiometricsInfo()?.supportedBiometricID.includes(__constants.BIOMETRICS_ID.FINGERPRINT))
                ? __constants.BIOMETRICS_ID.FINGERPRINT
                : __constants.BIOMETRICS_ID.FACE_ID
        }

        if (isForgotPINFlow) {
            params = { ...params, ...{ businessCode, userId, csid: window.localStorage.getItem('csId') } }
        }

        let headers = null;
        if (!isForgotPINFlow && window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                'x-acf-sensor-data': akamaiData,
                'user-agent1': getUserAgent1()
            }
        }

        const pattern = isForgotPINFlow ? 'forgotPIN/checkBiometricAuthentication' : 'checkBiometricAuthentication'
        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}${pattern}/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            params,
            callBack,
            kEVENT_TRACKING.ChannelEnum.BIOMETRICS,
            pattern,
            MOCKED_API?.TRANSMIT?.AUTHENTICATION) as Promise<AxiosResponse<BiometricResponse>>;
    }

    updateBiometricRegistrationResult(success: boolean, callBack?: Function, akamaiData?: string, softTokenSerialNumber?: string, softTokenSequenceNumber?: string) {
        const params = {
            softTokenSerialNumber,
            softTokenSequenceNumber,
            success
        }
        let headers = null;
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB && _.isString(akamaiData) && akamaiData.length > 0) {
            headers = {
                'x-acf-sensor-data': akamaiData,
                'user-agent1': getUserAgent1()
            }
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}updateBiometricRegistrationResult/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            params,
            callBack,
            kEVENT_TRACKING.ChannelEnum.BIOMETRICS,
            'updateBiometricRegistrationResult',
            MOCKED_API?.TRANSMIT?.REGISTRATION_RESULT) as Promise<AxiosResponse<BiometricResponse>>;
    }
}

const __instance__ = new BiometricApi();

export default __instance__;
