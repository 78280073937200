import _ from 'lodash';
import React, { ReactNode } from 'react';
import ApplicationContext from '../../../context/application-context';
import ContextData from '../../../context/context-data.interface';
import IToastUI from '../../../context/itoast.interface';
import './toast-ui.style.scss'
import { addLogoutTimer } from '../../../utils/timer.util';
import { AxiosResponse } from 'axios';
import { WAIT_FOR_IOS_CALLBACK } from '../../../api/api.core';
import { Modal } from '@citi-icg-172888/icgds-react';
import Button from '../../../ui-kit/button/button';
import CountDown from '../../../ui-kit/count-down/count-down.component';

interface State {
    toasts: { [key: string]: Toast }
    showToast?: boolean,
    toastRender?: ReactNode;
    showTimerPopup?: boolean;
}
interface Props { }

interface Toast {
    msg: string,
    toastid: string,
    timeout: number,
    actionCallback?: Function,
    closeCallback?: Function
}

export default class ToastUI extends React.Component<Props, State> implements IToastUI {

    context: ContextData | undefined;

    __toasts: { [key: string]: Toast } = {};

    constructor(p: Props) {
        super(p);

        this.state = {
            toasts: {},
        }
    }

    handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            this.setState({ showTimerPopup: false });
        }
    };

    componentDidMount() {
        this.context?.ToastService.setToastUI(this);
        document.addEventListener('keydown', this.handleKeyDown);

    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    onTimerContinueClick = () => {
        this.setState({ showTimerPopup: false });

        if (this.context?.Security.isSignedIn()) {
            this.context?.API.LOGIN_SERVICE.mobileKeepAlive(this.onKeepAliveSuccess).then(
                (resp: AxiosResponse<any>) => {
                    if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                        this.onKeepAliveSuccess(resp.data);
                    }
                }
            ).catch((error: any) => {
                console.log('Failed to extend session')
            })
        } else {
            this.context?.API.LOGIN_SERVICE.keepAlive(this.onKeepAliveSuccess).then(
                (resp: AxiosResponse<any>) => {
                    if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                        this.onKeepAliveSuccess(resp.data);
                    }
                }
            ).catch((error: any) => {
                console.log('Failed to extend session')
            })
        }
    }

    onKeepAliveSuccess(resp: any) {
        console.log('logout notification - onKeepAliveSuccess - ', resp);
        if (resp.keepAlive) {
            window.LOGOUT_TIMER_REACT = addLogoutTimer();
        } else {
            console.log('Cannot extend session')
        }
    }


    onTimerSignOffClick = () => {
        this.setState({
            showTimerPopup: false
        })
        window.ExpireSession();
    }

    showToast(msg: string, timeout: number, autoDismiss: boolean = true): boolean {
        this.setState({
            toastRender: this.createToast(msg),
            showToast: true
        });
        if (!timeout || (timeout < 1999)) {
            timeout = 5000;
        }
        //if (autoDismiss) {
        setTimeout(() => {
            this.setState({
                showToast: false
            })
        }, timeout)
        return true;
    }

    showActionToastUI(msg: string, actionText: string, actionCallBack?: Function | undefined, timeout?: number | undefined, autoDismiss: boolean = true): boolean {
        this.setState({
            toastRender: this.createToast(msg, actionText, actionCallBack, () => { }),
            showToast: true
        });
        if (!timeout || (timeout < 1999)) {
            timeout = 5000;
        }
        //if (autoDismiss) {
        setTimeout(() => {
            this.setState({
                showToast: false
            })
        }, timeout)
        //}
        return true;
    }

    showTimerPopUp(shown: boolean = true): boolean {
        this.setState({ showTimerPopup: shown });

        return true;
    }

    showPushNotifContentToast(shown: boolean, pushNotifUI?: ReactNode) {
        console.log("Push Notifications - showPushNotifContentToast ", shown);

        this.setState({
            showToast: shown,
            toastRender: pushNotifUI
        });

        return true;
    }

    createToast(msg: string = "Something went wrong!", actionText: string = "",
        actionCallBack?: Function, closeCallback?: Function) {
        return (
            <div className="citi-toast-wrapper">
                <div className="citi-toast">
                    <div className="toast-content">
                        {msg}
                    </div>
                    <div className="toast-actions-wrapper">
                        <div className="toast-action-container">
                            {
                                actionText && actionText !== "" ?
                                    <div className="toast-action">
                                        <p className={'custom-action'}
                                            onClick={(e) => {
                                                if (_.isFunction(actionCallBack)) {
                                                    actionCallBack();
                                                }
                                            }}
                                        >{actionText}</p>
                                    </div> : null
                            }
                            <div className="toast-action">
                                <p className="action-close" onClick={() => { this.setState({ showToast: false }) }}>
                                    <i className="lmnicon lmnicon-close"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderTimerPopUP() {
        return <>
            <Modal
                visible={this.state.showTimerPopup}
                footer={""}
                className="timer-popup-modal"
                wrapperClass="timer-popup-wrapper"
                closable={false}
                width='80%'>

                <div className="timer-popup">
                    <h3>
                        We haven't heard from you for a while.
                    </h3>
                    <div className='popup-content'>
                        For your security and protection, your connection will close if there is no activity within
                        <span className='countdown-timer'>
                            <CountDown
                                value={1000 * 60 * 0.5}
                                timerKey='logoutTimer'
                                format='ss'
                                onFinish={this.onTimerSignOffClick}
                            /> secs</span>
                    </div>
                    <div className='popup-content '>
                        Would you like to continue your session?
                        <div className='action-container'>
                            <Button className='buttonWithMargin action-click' onClick={this.onTimerSignOffClick} color='secondary'> Sign Off</Button>
                            <Button className='buttonWithMargin action-click' onClick={this.onTimerContinueClick} color='primary'>Continue</Button>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    }

    render() {
        return <div className="cbusol-overylay-container">
            {
                this.state.showToast ? <>
                    <div className="cbusol-toast-container">
                        {
                            this.state.toastRender
                        }
                    </div>
                </> : null
            }
            {
                this.state.showTimerPopup ? this.renderTimerPopUP() : undefined
            }
        </div>
    }

}

ToastUI.contextType = ApplicationContext