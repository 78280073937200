import _ from "lodash";
import http from "./api.core";
import { RequestMethod } from "./api.core";
import { AxiosResponse } from "axios";
import securityCtx from "../context/security-context";
import kGENERAL from "../common/constants/GENERAL";
import kPIWIK from "../common/constants/PIWIK";

let MOCKED_API: any

export interface PushNotifResponse {

}

export class PushNotifApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === "production");
        const m = require(isProd ? "./mocks/prod.mock.ts" : "./mocks/mocks.ts").default
        MOCKED_API = m
    }

    registerPushNotif(data: { [key: string]: any }, callBack?: Function) {
        const { deviceId, deviceType, deviceToken, serialNumber, sequenceNumber } = data;
        const params = {
            deviceId,
            deviceType,
            deviceToken,
            serialNumber,
            sequenceNumber,
            "uid": securityCtx?.getUserP86Id()
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}registerPushNotification/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.PUSH_NOTIFICATIONS,
            "registerPushNotification",
            MOCKED_API?.PUSH_NOTIFICATIONS?.RESPONSE) as Promise<AxiosResponse<PushNotifResponse>>;
    }

    unregisterPushNotif(data: { [key: string]: any }, callBack?: Function) {
        const { deviceId, deviceToken, serialNumber, sequenceNumber } = data;
        const params = {
            deviceId,
            deviceToken,
            serialNumber,
            sequenceNumber,
            "uid": securityCtx?.getUserP86Id()
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}unRegisterPushNotification/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.PUSH_NOTIFICATIONS,
            "unRegisterPushNotification",
            MOCKED_API?.PUSH_NOTIFICATIONS?.RESPONSE) as Promise<AxiosResponse<PushNotifResponse>>;
    }

    badgeDeduct(sourceIds: Array<string>, callBack?: Function) {
        // deduct 1 badge for these source id
        const params = {
            sourceIds,
            "uid": securityCtx?.getUserP86Id()
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}badgeDeduct/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.PUSH_NOTIFICATIONS,
            "badgeDeduct",
            MOCKED_API?.PUSH_NOTIFICATIONS?.RESPONSE) as Promise<AxiosResponse<PushNotifResponse>>;
    }

    badgeDeductAll(sourceIds?: Array<string>, callBack?: Function) {
        // payload without "sourceIds" - clear badge for all source id 
        // payload with "sourceIds" - clear badge for source in the payload
        let params = {
            "uid": securityCtx?.getUserP86Id()
        }

        if (_.isArray(sourceIds) && sourceIds.length > 0) {
            params = { ...params, ...{ sourceIds } }
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}badgeDeductAll/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.PUSH_NOTIFICATIONS,
            "badgeDeductAll",
            MOCKED_API?.PUSH_NOTIFICATIONS?.RESPONSE) as Promise<AxiosResponse<PushNotifResponse>>;
    }

    /**
     * Payload: 
     * {"userID":"102026","type":"2","pushNotificationType":"registerToken"}
     * @param callBack 
     * @returns 
     */
    getPushNotifHistory(data: { [key: string]: any }, callBack?: Function) {
        let headers = null;
        let params = {
            type: data.type,
            "userID": securityCtx?.getUserP86Id(),
            "pushNotificationType": "registerToken",
            "records":data.records
        }
        let access = "role";

        if (!data.isSignedIn) {
            const { jwtToken, serialNumber, sequenceNumber } = data;

            headers = {
                "m-ccb-jwt-token": jwtToken
            }
            params = { ...params, ...{ serialNumber, sequenceNumber } };
            access = "all";
        }

        return http.makeHttpRequest(
            RequestMethod.POST,
            `${window.ROOT}getPNHistoryList/${access}/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}`,
            headers,
            params,
            callBack,
            kPIWIK.ChannelEnum.PUSH_NOTIFICATIONS,
            "getPushNotificationHistory",
            MOCKED_API?.PUSH_NOTIFICATIONS?.HISTORY) as Promise<AxiosResponse<PushNotifResponse>>;
    }

}

const __instance__ = new PushNotifApi();

export default __instance__;