import http from './api.core';
import { RequestMethod } from './api.core';
import kGENERAL from "../common/constants/GENERAL";
import kPIWIK from "../common/constants/PIWIK";
import { AxiosResponse } from 'axios';

export class TransfersPaymentsAPI {

    // constructor() {
    //     const isProd = (process.env.NODE_ENV === 'production');
    //     const m = require(isProd ? './mocks/prod.mock' : './mocks/mocks').default
    //     MOCKED_API = m
    // }

getTransfersMenu(callBack?: Function) {
        // GET // https://mobileuat.citibusinessonline.com/transferMenuPage/15/en?_=1650985091252
        return http.makeHttpRequest(
            RequestMethod.GET,
            `${window.ROOT}transferMenuPage/${kGENERAL.APP_VERSION_DIR}/${kGENERAL.LANG}?_=${Date.now()}`,
            null,
            null,
            callBack,
            kPIWIK.ChannelEnum.MOBILE_TOKEN, "mobileTokenManagement",
            (process.env.NODE_ENV === 'production') ? {} : mockedTransfersMenu) as Promise<AxiosResponse<any>>;
    }

}

const mockedTransfersMenu = {
    "success": true,
    "message": "",
    "body": {
        "header": "Transfers and Payments",
        "unavailable": "Unavailable",
        "fxWiresInitiateToggle": true,
        "type": [
            {
                "typeName": "Internal Transfer",
                "available": true,
                "id": "internalTransfer",
                "list": [
                    {
                        "from": "Setup a New Transfer",
                        "key": "a4f50fa3-2885-42eb-8640-45853f836e58",
                        "url": "transferFromAccountPage/1",
                        "testId": "setupTransferPage-t",
                        "parentPage": "transferMenuPage",
                        "available": true
                    },
                    {
                        "from": "Planned and Pending",
                        "key": "5a118ae4-e7d8-4514-8f20-0e0e99fd4b74", "url": "plannedAndPendingTransfersListPage/transfer/first/1", "testId": "plannedAndPendingPage-t", "parentPage": "transferMenuPage", "available": true
                    }, { "from": "Completed", "key": "2cf1f34d-3a6a-4b3d-ab51-5d9ff66395ff", "url": "completedTransfersPage/first/1", "testId": "completedTransfersPage-t", "parentPage": "transferMenuPage", "available": true }]
            },
            {
                "typeName": "Wires", "available": true, "id": "wires",
                "list": [{ "from": "Setup Domestic Wire", "key": "c70c1a8b-af5d-45ae-bbdc-7ef98df41623", "url": "setUpWireAccountPage/new", "testId": "setupDomesticWirePage-t", "parentPage": "transferMenuPage", "available": true }, { "from": "Setup Domestic Wire using Model", "key": "d45dcfc7-bdbd-4dd8-8175-c046affbf66e", "url": "setUpWireUsingModelPage/first/1", "testId": "setUpWireUsingModelPage-t", "parentPage": "transferMenuPage", "available": true }, { "from": "Planned and Pending", "key": "e6708b02-1752-4455-a33f-b18857adbfc5", "url": "plannedAndPendingWireTransfersListPage/wire/first/1", "testId": "plannedAndPendingWires-t", "parentPage": "transferMenuPage", "available": true }, { "from": "Completed", "key": "8be66ae3-a59c-4fa9-9d4b-e8a484a66ac1", "url": "completedWirePage/first/1", "testId": "completedWirePage-t", "parentPage": "transferMenuPage", "available": true }]
            },
            {
                "typeName": "Bill Pay", "available": true, "id": "billPay",
                "list": [
                    {
                        "from": "Make Payment from List",
                        "key": "416d304a-a227-4a25-b620-48480554320a",
                        "url": "chooseAnAccountToUseForPaymentsPage/new",
                        "testId": "makePaymentFromList-t",
                        "parentPage": "transferMenuPage",
                        "available": true
                    },
                    {
                        "from":
                            "Review Past Payments", "key": "4029581d-5e86-4952-8eba-37c6d5457fda", "url": "reviewAllPayments/true", "testId": "makePaymentFromList-t", "parentPage": "transferMenuPage", "available": true
                    }]
            }]
    }
}



const transfersPaymentsApi = new TransfersPaymentsAPI();

export default transfersPaymentsApi;