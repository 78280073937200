import React, { FormEvent } from "react";

import __constants from '../../../common/constants';
import LandingHeader from "../../header/header.component";
import { ErrorMessages } from "../../shared/messages";


import './switch-account.scss';
import ApplicationContext from "../../../context/application-context";
import ContextData from "../../../context/context-data.interface";
import CitiRegEx from "../../../utils/CitiRegEx.util";
import { APP_LOCATIONS } from "../../../routes/routes";
import { addLogoutTimer } from "../../../utils/timer.util";
import { postSignIn, compareVersion, preLogin } from '../../../utils/common.util';
import _ from '../../../lodash';
import CBLInput from '../../../ui-kit/cbusol-input/CBLInput'
import { DEVICE_TYPE } from "../../../device/xdevice";
import { FCheckBox } from "../../../ui-kit/checkbox/checkbox.component";
import Alert from "../../../ui-kit/alert/alert.component";
import Button from '../../../ui-kit/button/button';
import { maskMiddleText } from "../../../utils/mask.util";
import { WAIT_FOR_IOS_CALLBACK } from "../../../api/api.core";
import BUILD_UTIL from "../../../utils/build.util";
import securityCtx from '../../../context/security-context';
import { analyticsBranded } from "../../../utils/piwik.util";

/**
 * Component for Login Page (& Redirected from Switch Accounts)
 */

interface Props {
    history?: any,
    location?: any
}

interface State {
    businessCode: string,
    userId: string,
    rememberCodes: boolean,
    password: string,
    passwordUnmasked: string,
    otp1: string,
    otp1Unmasked: string,

    //validation specific variables
    isError: boolean,
    errorMessage?: string,
    warningMessage?: string,
    containsHyperlink: boolean,

    //input field errors
    businessCodeError?: string,
    userIdError?: string,
    passwordError?: string,
    otpError?: string,

    isLoading?: boolean

    businessCodeUnmasked: string,
    userIdUnmasked: string,

    jwtAvailable?: boolean,

    showCancelBtn?: boolean,
    isSwitchAccounts?: boolean
    tmxFetchComplete: boolean
}

// 4.5 mins to expire
const TMX_TIMEOUT_DELAY = 4.5 * 60 * 1000;
// 45 seconds to expire
const MOCK_TMX_TIMEOUT_DELAY = 45 * 1000;

export default class SwitchAccount extends React.Component<Props, State> {

    props: Props;

    context: ContextData | undefined;

    deviceDetector: any;

    hideAll: NodeJS.Timeout;

    constructor(props: Props) {
        super(props);
        this.hideAll = setTimeout(() => { }, 0);
        this.props = props;
        this.state = {
            password: '',
            passwordUnmasked: '',
            businessCode: '',
            userId: '',
            otp1: '',
            otp1Unmasked: '',
            isError: false,
            errorMessage: '',
            containsHyperlink: false,
            rememberCodes: false,
            isLoading: false,
            businessCodeUnmasked: '',
            userIdUnmasked: '',
            jwtAvailable: this.context?.API.LOGIN_SERVICE.hasJwToken(),
            tmxFetchComplete: true
        };
    }

    componentDidMount() {
        //only goto native page after event is called, otherwisw C41630-34176 issue will persist
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible" && window.isLogoutFlag) {
                console.log("visibilitychange event called...")
                window.isLogoutFlag = false
                this.context?.DEVICE.goToNativePage("mfa_logout");
            }
        })

        this.context?.Router?.__setHistory(this.props.history);
        this.context?.Security?.setSignedIn(false);
        this.context?.Security?.setHomePage(false);
        this.setState({
            jwtAvailable: this.context?.API.LOGIN_SERVICE.hasJwToken()
        });

        this.deviceDetector = setInterval(this.deviceTypeTimerCallBack, 2000);

        const loc_data = this.props?.history?.location?.data;
        if (loc_data) {
            console.log('setting switch accounts state to', (loc_data.action === 'switch-accounts'));
            this.setState({
                isSwitchAccounts: (loc_data.action === 'switch-accounts')
            });
            window.isSwitchAccounts = (loc_data.action === 'switch-accounts')
        }

        if (this.context?.API.LOGIN_SERVICE.hasJwToken() && this.props.location.pathname === APP_LOCATIONS.SignOnPage)
            this.context?.API.LOGIN_SERVICE.fetchRememberedUserDetails(this.successFetchRememberedUserDetails).then(
                response => {
                    if (response.status !== WAIT_FOR_IOS_CALLBACK)
                        this.successFetchRememberedUserDetails(response.data)
                    else
                        console.log('waiting for ios call', response)
                }
            )
                .catch((error) => { console.log(error) });

        window.LOGOUT_TIMER_REACT = addLogoutTimer(this.props.location?.pathname);
        preLogin();
    }

    componentWillUnmount() {
        window.tmxUICallback = undefined
    }


    deviceTypeTimerCallBack = () => {
        if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            this.setState({
                showCancelBtn: true
            });
            clearInterval(this.deviceDetector);
        }
    }


    successFetchRememberedUserDetails = (response: any) => {
        if (response.success) {
            const bCode = (response.businessCode as string).slice(1).replace(/^0+/, '');
            this.setState({
                businessCode: bCode,
                businessCodeUnmasked: bCode,
                userId: response.userCode,
                userIdUnmasked: response.userCode,
                isSwitchAccounts: true,
                rememberCodes: this.context?.API.LOGIN_SERVICE.hasJwToken() ? true : false
            });
        }
    }

    onBusinessCodeBlur = () => {
        const bCode: string = this.state.businessCode;
        const masked = maskMiddleText(bCode);
        this.setState({
            businessCodeUnmasked: bCode ? bCode : '',
            businessCode: masked,
            isSwitchAccounts: true,
        });
    }

    onBusinessCodeFocus = () => {
        const bCode = this.state.businessCodeUnmasked;
        this.setState({
            businessCode: bCode ? bCode : '',
            isSwitchAccounts: true
        });

        this.onInputRemoveErrorMessageFocus();
    }

    onInputRemoveErrorMessageFocus = () => {
        if (this.state.errorMessage && this.state.errorMessage.length > 0) {
            this.setState({
                errorMessage: undefined
            });
        }
    }

    onInputKeyDown = (e: React.KeyboardEvent<Element>) => {

        if (this.disabledBtn()) {
            return;
        }

        if (e.key.toLowerCase() !== 'enter') {
            return;
        }

        this.setState({ isLoading: true });
        this.handleMobileBankingSignOn1();
    }

    inputInfo(): Object[] {
        return ([
            {
                onChangeFunc: this.validateBusinessCode,
                label: __constants.INPUT_ENTER_BUSINESS_CODE,
                val: this.state.businessCode,
                error: this.state.businessCodeError,
                onBlur: this.onBusinessCodeBlur,
                onFocus: this.onBusinessCodeFocus,
                type: 'text',
                show: true,
                businessCode: true,
                disabled: this.state.isLoading,
                onlyNumbers: true
            },
            {
                onChangeFunc: this.validateUserId,
                label: __constants.INPUT_ENTER_USER_ID,
                val: this.state.userId,
                error: this.state.userIdError,
                onBlur: (e: Event) => { this.setState({ userId: maskMiddleText(this.state.userIdUnmasked) }) },
                onFocus: (e: Event) => { this.setState({ userId: this.state.userIdUnmasked }) },
                type: 'text',
                show: true,
                disabled: this.state.isLoading,
                onlyNumbers: true
            },
            {
                onCheckChange: this.handleRememberCheckChange,
                label: __constants.REMEMBER_CODE_AND_ID,
                val: this.state.rememberCodes,
                type: 'checkbox',
                show: (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB),
                checked: this.state.rememberCodes,
                disabled: this.state.isLoading
            },
            {
                onChangeFunc: (e: Event) => { window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? this.passwordMask((e.target as any).value) : this.setState({ password: (e.target as any).value }) },
                label: __constants.INPUT_ENTER_PASSWORD,
                val: this.state.password,
                type: window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? 'text' : 'password',
                onFocus: (e: Event) => { this.onPasswordFocus() },
                error: this.state.passwordError,
                enableForgotPasswordLink: true,
                show: true,
                maxLength: "10",
                disabled: this.state.isLoading,
                showHiddenIcon: true
            },
            {
                onChangeFunc: this.validateOtp,
                label: __constants.INPUT_ENTER_OTP1,
                val: this.state.otp1,
                onFocus: (e: Event) => { this.setState({ otp1: '' }) },
                optional: true,
                type: window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? 'text' : 'password',
                show: true,
                maxLength: "8",
                disabled: this.state.isLoading,
                onlyNumbers: true
            }
        ])
    }

    passwordMask = (value: string) => {

        const passwordValue = _.cloneDeep(value);

        const passwordUnmaskedValue = this.state.passwordUnmasked;

        let showLength = 1;


        let offset = passwordValue.length - passwordUnmaskedValue.length;

        if (offset > 0) {
            this.setState({
                passwordUnmasked:
                    passwordUnmaskedValue +
                    passwordValue.substring(
                        passwordUnmaskedValue.length,
                        passwordUnmaskedValue.length + offset
                    )
            });
        } else if (offset < 0) {
            this.setState({
                passwordUnmasked: passwordUnmaskedValue.substring(0, passwordUnmaskedValue.length + offset)
            });
        }

        this.setState({
            password:
                passwordValue
                    .substring(0, passwordValue.length - showLength)
                    .replace(/./g, "•") +
                passwordValue.substring(
                    passwordValue.length - showLength,
                    passwordValue.length
                )
        });

        // Set the timer
        clearTimeout(this.hideAll);
        this.hideAll = setTimeout(() => {
            this.setState({
                password: passwordValue.replace(/./g, "•")
            });
        }, 2000);
    }

    otp1Mask = (value: string) => {

        const otp1Value = _.cloneDeep(value);

        const otp1UnmaskedValue = this.state.otp1Unmasked;

        let showLength = 1;


        let offset = otp1Value.length - otp1UnmaskedValue.length;

        if (offset > 0) {
            if (!CitiRegEx.onlyNumbers(otp1Value.substring(otp1UnmaskedValue.length, otp1UnmaskedValue.length + offset))) {
                return;
            }
            this.setState({
                otp1Unmasked:
                    otp1UnmaskedValue +
                    otp1Value.substring(
                        otp1UnmaskedValue.length,
                        otp1UnmaskedValue.length + offset
                    )
            });
        } else if (offset < 0) {
            this.setState({
                otp1Unmasked: otp1UnmaskedValue.substring(0, otp1UnmaskedValue.length + offset)
            });
        }

        this.setState({
            otp1:
                otp1Value
                    .substring(0, otp1Value.length - showLength)
                    .replace(/./g, "•") +
                otp1Value.substring(
                    otp1Value.length - showLength,
                    otp1Value.length
                )
        });

        // Set the timer
        clearTimeout(this.hideAll);
        this.hideAll = setTimeout(() => {
            this.setState({
                otp1: otp1Value.replace(/./g, "•")
            });
        }, 2000);
    }

    onPasswordFocus = () => {
        this.setState({ password: '' })
        const current = Math.abs((new Date()).getTime())
        const delay = BUILD_UTIL.isProduction() ? TMX_TIMEOUT_DELAY : MOCK_TMX_TIMEOUT_DELAY

        const ticketTime = this.context?.Security.getTMXTicketIdTime()


        if (ticketTime === 0) {
            preLogin(this.onTMXPreLoginComplete)
            return;
        }

        let delta = delay
        if (ticketTime && ticketTime > 0) {
            delta = current - ticketTime
            console.log('Delta: 304', delta);
        }
        console.log('delay: ', delay);
        console.log('Ticket time: ', ticketTime);
        console.log('Current time: ', current);
        console.log('Delta: ', delta);

        if (delta >= delay || securityCtx?.getTMXTicketId() === '') {
            console.log('Possible tmx ticket expiration, fetching again')
            this.setState({ tmxFetchComplete: false })
            preLogin(this.onTMXPreLoginComplete)
        } else {
            this.onTMXPreLoginComplete(null, true)
        }

        // else do nothing you are all set to go
    }

    onTMXPreLoginComplete = (data: any, success: boolean) => {

        this.setState({ tmxFetchComplete: true })

        if (!success) {
            console.log('Tmx Call failed');
            return;
        }

        console.log('TMX renewal complete.. we are good to go');
    }

    inputLabel(text: string, optional: Boolean) {
        let className: string = `${optional ? null : 'inputLabel'}`;
        return <label className={className} >{text}</label>

    }

    handleRememberCheckChange = (status: boolean) => {
        this.setState({
            rememberCodes: status
        });
        if ((!status) && this.context?.API.LOGIN_SERVICE.hasJwToken()) {
            console.log('clear jw token');
            this.context.API.LOGIN_SERVICE.clearJwToken(true);
        }
    }

    renderInputView(data: { [key: string]: any }) {
        return (
            <>
                {
                    data.show ?
                        data.type === 'checkbox' ?
                            <FCheckBox label={data.label} onCheckedChange={data.onCheckChange} checked={data.checked} />
                            :
                            <CBLInput
                                label={data.label}
                                value={data.val}
                                key={data.label}
                                type={data.type}
                                placeholder={data.placeholder}
                                onChange={data.onChangeFunc}
                                onBlur={data.onBlur ? data.onBlur : null}
                                onFocus={data.onFocus ? data.onFocus : this.onInputRemoveErrorMessageFocus}
                                onKeyDown={this.onInputKeyDown}
                                required={!data.optional}
                                autoFocus={data.autoFocus}
                                isBusinessCode={data.businessCode}
                                disabled={data.disabled ? data.disabled : false}
                                maxLength={data.maxLength ? data.maxLength : null}
                                numericInput={data.onlyNumbers ? data.onlyNumbers : false}
                                showHiddenIcon={data.showHiddenIcon ? data.showHiddenIcon : false}
                            /> : null
                }
                {
                    data.enableForgotPasswordLink ?
                        <div className='link-end'>
                            <Button
                                color="ghost" id="id-frgt-pwd-tip"
                                onBlur={() => { }}
                                onClick={this.handleForgotPassword}>
                                {__constants.FORGOT_PASSWORD}
                            </Button>
                        </div>
                        : null
                }
                {
                    data.error ?
                        <p className="error-message" key={`k${data.label}`}>
                            {data.error}
                        </p> :
                        null
                }
            </>
        );
    }

    handleForgotPassword = () => {
        document.getElementById('id-frgt-pwd-tip')?.focus();

        const { businessCodeUnmasked, userIdUnmasked } = this.state;
        this.props.history.push({
            pathname: APP_LOCATIONS.ForgotPasswordPage,
            state: {
                businessCode: businessCodeUnmasked,
                userId: userIdUnmasked
            }
        });
    }

    handleSubmitButtonCick = (e: Event | FormEvent) => {

        analyticsBranded(6, "Button: Sign In", "")

        if (this.state.errorMessage && this.state.errorMessage.length > 0) {
            this.setState({ errorMessage: undefined });
        }

        e.preventDefault();
        const data = { "isBusinessCodeError": false, "isUserIdError": false };
        data.isBusinessCodeError = (this.state.businessCodeUnmasked == null || this.state.businessCodeUnmasked === "" || !CitiRegEx.businessCode(this.state.businessCodeUnmasked));

        data.isUserIdError = (this.state.userIdUnmasked == null || this.state.userIdUnmasked === "" || !CitiRegEx.userCode(this.state.userIdUnmasked));
        this.setState({ businessCodeError: data.isBusinessCodeError ? 'Invalid Business Code' : undefined });
        this.setState({ userIdError: data.isUserIdError ? 'Invalid User ID' : undefined });

        if (data.isBusinessCodeError || data.isUserIdError) {
            return;
        }

        this.setState({ isLoading: true });

        this.handleMobileBankingSignOn1();

    }



    handleSuccessMobileBankingSignOnResponse(data: { [key: string]: any }) {

        if (this.state.rememberCodes) {
            this.context?.API.LOGIN_SERVICE.setJwToken(data.jw);
        }
        this.context?.Security?.setUserP86Id(_.get(data, 'userP86Id', ''));
        if (data.preSignOn === "NEW_USER_CBUSOLM_LOGIN_SECURITY_INFORMATION") {
            console.log("This is coming to NEW_USER_CBUSOLM_LOGIN_SECURITY_INFORMATION");
            this.handleFailedMobileBankingSignOnResponse(_.get(data, 'errors', null), true);
            return;

        }

        //Added PIWIK Call for successful login
        let device = 'Web';
        if (window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            device = window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID ? 'Android' : 'iOS'
        }
        analyticsBranded(3, `Successful Login CBusOL Mobile ${device}`, window.location.href, false, true)
        if (data.demo) {
            window.ROOT = window.GLOBAL_ROOT.includes("localhost") ? "demo/" : "/demo/";
        } else {
            window.ROOT = "";
        }

        securityCtx?.setTMXTicketId('');
        postSignIn(data);
    }

    successallbackhandleSuccessMobileBankingSignOnResponse = (resp: { [key: string]: any }) => {
        if (_.get(resp, 'success', false)) {
            this.handleSuccessMobileBankingSignOnResponse(resp);
        } else {
            this.handleFailedMobileBankingSignOnResponse(_.get(resp, 'errors', null));
        }
        this.setState({
            isLoading: false
        });
    }

    handleMobileBankingSignOn1() {
        if (window.isAkamaiMobile && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
            const args = [{ "foo": "bar" }];
            this.context?.DEVICE.getAkamai((response: any) => {
                const akamaiData = _.get(response, 'akamaiData', "");
                this.handleMobileBankingSignOn2(akamaiData);
            }, (fail: any) => {
                console.log("AKAMAI DATA RETRIVAL FAILED");
            }, args);
        } else {
            this.handleMobileBankingSignOn2();
        }
    }

    handleMobileBankingSignOn2(akamaiData?: string) {
        console.log('signon...');
        this.context?.API.LOGIN_SERVICE.login(this.state.businessCodeUnmasked, this.state.userIdUnmasked, window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? this.state.passwordUnmasked : this.state.password, window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ? this.state.otp1Unmasked : this.state.otp1, this.successallbackhandleSuccessMobileBankingSignOnResponse, this.state.rememberCodes, akamaiData)
            .then(resp => {
                if (resp.status !== WAIT_FOR_IOS_CALLBACK)
                    this.successallbackhandleSuccessMobileBankingSignOnResponse(resp.data)
                else
                    console.log('waiting for ios call', resp)
            })
            .catch(error => {
                this.handleFailedMobileBankingSignOnResponse(error);
            });
    }

    handleFailedMobileBankingSignOnResponse = (error: Array<{ [key: string]: any }>, showServerError?: boolean) => {
        console.log('Sign on failed..', error);

        preLogin();

        if (_.get(error, '[0].systemMaintenance', false)) {
            showServerError = true;
        }

        this.setState({ errorMessage: _.get(error, '[0].value', ErrorMessages.LOGIN_ERROR) });
        this.setState({ containsHyperlink: !showServerError });
        this.setState({
            isLoading: false,
            password: '',
            passwordUnmasked: '',
            otp1: '',
            otp1Unmasked: ''
        });
    }

    disabledBtn = () => {
        return (this.state.businessCode && this.state.userId && this.state.password?.length > 0 && this.state.tmxFetchComplete) ? false : true;
    };

    handleCancelButtonClick = () => {

        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS || window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {

            if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
                this.setState({
                    businessCode: "",
                    businessCodeUnmasked: "",
                    userId: "",
                    userIdUnmasked: "",
                    password: "",
                    otp1: "",
                    errorMessage: undefined,
                    warningMessage: "",
                });
                // if (compareVersion('16')) {
                //     this.context?.DEVICE.goToNativePage();
                // } else {
                //     this.context?.DEVICE.changeBrowserURL("mfa_logout", 1)
                // }
            } else {
                this.setState({
                    businessCode: "",
                    businessCodeUnmasked: "",
                    userId: "",
                    userIdUnmasked: "",
                    password: "",
                    otp1: ""
                });
                // if (compareVersion('16')) {
                //     this.context?.DEVICE.goToNativePage();
                // } else {
                //     this.context?.DEVICE.changeBrowserURL("", 1)
                // }
            }
            this.context?.DEVICE.goToNativePage();
            this.context?.DEVICE.changeBrowserURL("mfa_logout", 1)
            const args = [{ "foo": "bar" }]
            this.context?.DEVICE.mobileTokenProvisioned((response: any) => {
                if (!window.DEVICE_INFO_BAK)
                    window.DEVICE_INFO_BAK = { ...response }
                if (response.success) {
                    this.context?.DEVICE.launchSecondBrowserURL('', 1);
                }
            }, (fail: any) => {
                console.log("common mobileTokenProvisioned FAILED");
            }, args);
        }
        else {
            this.props.history.push({
                pathname: APP_LOCATIONS.HomePage
            });
        }
    }

    validateBusinessCode = (e: Event) => {
        const bCode: string = (e.target as any).value;

        if (CitiRegEx.onlyNumbers(bCode)) {
            this.setState({
                businessCode: bCode
            });
            if (this.state.jwtAvailable) {
                this.handleRememberCheckChange(false);
            }
        }
    }

    validateOtp = (e: Event) => {
        const otp: string = (e.target as any).value;
        if (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB) {
            this.otp1Mask(otp);
        } else {
            if (CitiRegEx.onlyNumbers(otp)) {
                this.setState({
                    otp1: otp
                });
            }
        }
    }

    validateUserId = (e: Event) => {
        const user_id: string = (e.target as any).value;
        if (CitiRegEx.onlyNumbers(user_id)) {
            this.setState({
                userIdUnmasked: user_id,
                userId: user_id
            });
        }

        if (this.state.jwtAvailable) {
            this.handleRememberCheckChange(false);
        }
    }

    render() {
        return (
            <>
                <LandingHeader
                    // showFDICLogo 
                    title={__constants.LOG_IN_TO_BANKING} />

                <div className="react-container auto-overflow">
                    {
                        (this.state.errorMessage) ? <Alert type='error' content={this.state.errorMessage} containsHyperlink={this.state.containsHyperlink} /> :
                            this.state.warningMessage ? <Alert type='warning' content={this.state.warningMessage} /> :
                                null
                    }

                    <form autoComplete="off" onSubmit={this.handleSubmitButtonCick}>
                        {this.inputInfo().map((val) => {
                            return this.renderInputView(val);
                        })}

                        <div className="login-action-btn-container">

                            <Button
                                color="primary"
                                disabled={this.state.isLoading || this.disabledBtn()}
                                className='buttonWithMargin'
                                showLoading={this.state.isLoading}
                                role="button"
                                aria-label={__constants.LOG_IN}
                                onMouseEnter={() => {
                                    try {
                                        if (window.BioCatchApi) {
                                            window.BioCatchApi.client.flush();
                                        }

                                        if (compareVersion('17')) {
                                            this.context?.DEVICE.bioCatchFlush();
                                        }
                                    } catch { }
                                }}
                                onTouchStart={() => {
                                    try {
                                        if (window.BioCatchApi) {
                                            window.BioCatchApi.client.flush();
                                        }

                                        if (compareVersion('17')) {
                                            this.context?.DEVICE.bioCatchFlush();
                                        }
                                    } catch { }
                                }}
                                onClick={this.handleSubmitButtonCick}>
                                {
                                    __constants.LOG_IN
                                }
                            </Button>

                            {
                                this.state.showCancelBtn ?
                                    <Button
                                        onClick={this.handleCancelButtonClick}
                                        color="ghost"
                                        className='buttonWithMargin'>
                                        {__constants.CANCEL}
                                    </Button>
                                    : null
                            }
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

SwitchAccount.contextType = ApplicationContext;
