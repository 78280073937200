import { createRoot } from "react-dom/client";
import _ from "lodash";
import { APP_LOCATIONS } from "../routes/routes";
import { WAIT_FOR_IOS_CALLBACK } from "../api/api.core";
import device, { DEVICE_TYPE } from "../device/xdevice";
import loginService from "../api/login.api";
import securityCtx from "../context/security-context";
import loanContext from "../context/loan-context";
import wireInitiateContext from "../context/wire-initiate-context";
import fx_pulse_Context from "../context/fx-pulse-context";
import routerCtx from "../context/router-context";
import actionRequiredCtx from "../context/action-required-context";
import preLoginCtx from "../api/pre-login.api";
import { TMXProfile } from "../js/fp_AA";
import loadBioCatch from "./biocatch-loader.util";
import moment from "moment-timezone";
import CookieConsentScriptLoader, {
    CookieBlockerScriptLoader,
} from "./cookie-consent";
import GlassboxScriptLoader from "./glassbox";
import BUILD_UTIL from "./build.util";
import { updateCdcbLoginId } from "../components/cdcb/login/login.util";
import kAPPROVALS from "../common/constants/APPROVALS";
/**
 * handle login
 */

enum PRE_SIGNON {
    HOME_PAGE = "#homePageFromSignIn",
    PUSH_NOTIF = "#mfaNotifications",
    TMX_CHALLENGE_PHONE_OTP = "#tmxChallengePhoneOTP",
    TMX_CHALLENGE_EMIAL_OTP = "#tmxChallengeEmailOTP",
    BANKING_ENROLLMENT = "#mobileBankingEnrollment",
    RESET_PASSWORD = "#changeMixedPasswordPage",
    RCD_ONLY_PAGE = "#rcdOnlyPageFromSignIn",
    EMAIL_CAPTURE_PRIMARY_PAGE = "#emailCapturePrimaryPage",
    USER_AGREEMENT_PAGE = "#requireUserAgreementPageAcceptDeline",
    TOKEN_REGISTRATION = "#requireTokenRegistrationPage",
    EMAIL_VERIFY_PRIMARY_PAGE = "#emailVerifyPrimaryPage",
}

function _setActionRequired(data: { [key: string]: any }) {
    console.log("postSignIn - setActionRequired :", data);
    if (data.actionRequire) {
        actionRequiredCtx?.setActionRequired(true);
        actionRequiredCtx?.setCountDownDay(data.countDownDay);
        actionRequiredCtx?.setTokenRequired(data.phoneCaptureOTPVerify);
        actionRequiredCtx?.setTokenUser(data.tokenUser);
        actionRequiredCtx?.setHasPhoneNumbers(data.hasOTPSupportedDevice);
        actionRequiredCtx?.setHasSystemAdmin(data.hasSystemAdmin);
        actionRequiredCtx?.setCCBUser(data.isCCBSegment);
        actionRequiredCtx?.setCCBSynced(data.isCCBSynced);
        actionRequiredCtx?.setGWSyncRequired(data.gatewaySyncRequired);
        actionRequiredCtx?.setEmailCaptureRequired(
            data.gatewayEmailCaptureRequired
        );
    } else {
        actionRequiredCtx?.setActionRequired(false);
    }
}
export let scrollToTheTop: (timer: number) => void = function (timer) {
    window.setTimeout(
        "document.getElementsByClassName('react-container')[0].scrollTo(0,0)",
        timer
    );
};
export function postSignIn(data: { [key: string]: any }) {
    window.history.pushState({ error: "signout" }, "", "#signOutPage");
    if (!securityCtx?.isSignedIn()) {
        console.log("postSignIn - setSignedIn");
        securityCtx?.setSignedIn(true);
    }
    securityCtx?.setMobileTokenUser(data.mobileTokenUser);
    console.log("data.isSplCharPwFriendly:" + data.isSplCharPwFriendly);
    window.isSplCharPwFriendly = data.isSplCharPwFriendly;
    _setActionRequired(data);

    //Toggle to check is LOAN functionality enabled or not
    loanContext?.setCDCB_LOAN_TOGGLE(false);
    wireInitiateContext.setIRSFedTaxPayToggle(data.preventIRSFedTaxPay);
    //Toggle to check if user is CDCB or not.
    if (data.cdcb !== null && data.cdcb !== undefined && data.cdcb) {
        //If user is CDCB and if isLoansFriendly enabled then show 'Lending' in Home page
        if (
            data.isLoansFriendly !== null &&
            data.isLoansFriendly !== undefined &&
            data.isLoansFriendly
        ) {
            loanContext?.setCDCB_LOAN_TOGGLE(data.isLoansFriendly);
        }

        //Toggle to check is Foreign exchange functionality enabled or not
        if (
            data.isFxFriendly !== null &&
            data.isFxFriendly !== undefined &&
            data.isFxFriendly
        ) {
            fx_pulse_Context?.setFOREIGN_EXCHANGE_TOGGLE(data.isFxFriendly);
        }
    }

    if (
        data.cdcbRBMobileBanking !== null &&
        data.cdcbRBMobileBanking !== undefined &&
        data.cdcbRBMobileBanking
    ) {
        securityCtx?.setCDCBRBMobileBanking(data.cdcbRBMobileBanking);
    }

    //Save below data for foreign-Wire-Submit API
    if (data.loginIpAddress !== null && data.loginIpAddress !== undefined) {
        wireInitiateContext?.setUserInfo({ loginIpAddress: data.loginIpAddress });
    }
    if (
        data.initialBioCatchCsid !== null &&
        data.initialBioCatchCsid !== undefined
    ) {
        wireInitiateContext?.setUserInfo({
            bioCatchCsiId: data.initialBioCatchCsid,
        });
    }
    if (data.loginTmxRequestId !== null && data.loginTmxRequestId !== undefined) {
        wireInitiateContext?.setUserInfo({
            loginTMXRequestId: data.loginTmxRequestId,
        });
    }
    // wireInitiateContext end

    // cdcb login
    if (window.DEVICE_INFO.autoGenerateSoftToken) {
        console.log("CDCB Login - updateCdcbLoginId ...");
        updateCdcbLoginId(data.cdcbLoginId);
    }

    const page = _.get(data, "preSignOn", PRE_SIGNON.HOME_PAGE);
    switch (page) {
        case PRE_SIGNON.PUSH_NOTIF:
            routerCtx?.navigate(APP_LOCATIONS.PushNotifLanding);
            break;
        case PRE_SIGNON.TMX_CHALLENGE_PHONE_OTP:
            routerCtx?.navigate(APP_LOCATIONS.TMXChallengePhoneOTP);
            break;
        case PRE_SIGNON.TMX_CHALLENGE_EMIAL_OTP:
            routerCtx?.navigate(APP_LOCATIONS.TMXChallengeEmailOTP);
            break;
        case PRE_SIGNON.BANKING_ENROLLMENT:
            routerCtx?.navigate(APP_LOCATIONS.BANKING_ENROLLMENT);
            break;
        case PRE_SIGNON.RESET_PASSWORD:
            routerCtx?.navigate(APP_LOCATIONS.ResetPasswordComponent);
            break;
        case PRE_SIGNON.RCD_ONLY_PAGE:
            routerCtx?.navigate(APP_LOCATIONS.DepositCheckLanding);
            break;
        case PRE_SIGNON.EMAIL_CAPTURE_PRIMARY_PAGE:
            routerCtx?.navigate(APP_LOCATIONS.EmailCapturePrimaryPage);
            break;
        case PRE_SIGNON.EMAIL_VERIFY_PRIMARY_PAGE:
            routerCtx?.navigate(APP_LOCATIONS.EmailVerifyPrimaryPage);
            break;
        case PRE_SIGNON.USER_AGREEMENT_PAGE:
            routerCtx?.navigate(APP_LOCATIONS.RequireUserAgreement);
            break;
        case PRE_SIGNON.TOKEN_REGISTRATION:
            routerCtx?.navigate(APP_LOCATIONS.RequireTokenRegistration);
            break;
        default:
            routerCtx?.navigate(APP_LOCATIONS.SignInHome);
    }
}

/**
 * remove child for bottom navigation when logout
 */
export function removeChildForBottomNav() {
    const navElement: Element | null =
        document.querySelector("#react-bottom-nav");
    if (navElement) {
        _.delay(() => {
            createRoot(navElement).unmount();
        }, 200);
    }
}

/**
 * Call TMX profiling
 */ function _handlePreLoginResp(resp: { [key: string]: any }) {
    // console.log('_handlePreLoginResp resp - ' + JSON.stringify(resp));
    if (resp) {
        securityCtx.setCdcbEncryptSessionUrl(resp.cdcbLoginEncryptionSessionUrl);
        securityCtx.setBiometricsDown(resp.biometricsDown);

        if (resp.toolkitFriendly) {
            TMXProfile(
                resp.toolkitFriendly,
                resp.profileUrl,
                resp.orgId,
                resp.tmxSessionId
            );
        }
        securityCtx?.setTMXToolkitFriendly(resp.toolkitFriendly);

        if (_.isString(resp.ticketId) && resp.ticketId.length > 0) {
            console.log("Response ticket id if -> ");
            securityCtx?.setTMXTicketId(resp.ticketId);
            if (window.tmxUICallback) {
                window.tmxUICallback(resp, true);
            }
        } else {
            console.log("Response ticket id else -> ");
            if (window.tmxUICallback) {
                window.tmxUICallback(resp, false);
            }
        }

        if (_.isString(resp.tmxSessionId) && resp.tmxSessionId.length > 0) {
            console.log("Response toolkit id if -> ");
            securityCtx?.setTMXSessionId(resp.tmxSessionId);
        } else {
            console.log("Response toolkit id else -> ");
        }

        if (resp.showKeepAlivePopup !== undefined) {
            window.CONFIG_TRIGGER_KEEP_ALIVE = resp.showKeepAlivePopup || false;
            // resp.showKeepAlivePopup;
        }

        const csid = _.get(resp, "csId", "");
        const bioCatchUrl = _.get(resp, "biocatchUrl", "");

        if (csid !== "") {
            window.localStorage.setItem("csId", csid);
            window.sessionStorage.setItem("csId", csid);
            window.csid = csid;
        }

        if (csid !== "" && bioCatchUrl !== "") {
            if (compareVersion("17") && window.THE_DEVICE_TYPE !== DEVICE_TYPE.WEB) {
                window.cdApi = undefined;

                const ele = document.querySelector("#jsBioCatch");
                if (ele) {
                    ele.parentNode?.removeChild(ele);
                }
                if (window.BioCatchApi) {
                    window.BioCatchApi = undefined;
                }
            } else {
                startBioCatchTimer(bioCatchUrl, csid);
            }

            device.updateBioCatchCSID({ csid });
        } else {
            console.log("Not initializing biocatch. No details provided");
        }

        const gEnv = _.get(resp, "env", BUILD_UTIL.environment());
        window.SERVER_ENV = gEnv;
        const cookieConsentToggle = _.get(resp, "cookieConsent", false);

        if (cookieConsentToggle) {
            if (
                window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB ||
                (window.cookieConsentApps === true && compareVersion("17"))
            ) {
                CookieConsentScriptLoader(gEnv, (s: boolean, i: string) => {
                    console.log("Cookie consent Success:", s, "\tResult:", i);
                    if (s === true) {
                        CookieBlockerScriptLoader((sr: boolean, ir: string) => {
                            console.log(
                                "Cookie blocker script status: success",
                                sr,
                                " Result:",
                                ir
                            );
                        });
                    }
                });
            } else {
                console.log(
                    "Device detected, not loading cookie consent script",
                    window.THE_DEVICE_TYPE
                );
            }
        } else {
            console.log("Cookie Consent Toggle is Off");
        }

        const glassboxToggle = _.get(resp, "glassboxToggle", false);

        //Glassbox script initialization
        if (glassboxToggle) {
            GlassboxScriptLoader(gEnv, (s: boolean, i: string) => {
                console.log("Glassbox script load Success:", s, "\tResult:", i);
                if (window._detector) {
                    console.log(
                        "glassbox detector created successfully: ",
                        window._detector
                    );
                } else {
                    console.log("glassbox detector is null");
                }
            });
        } else {
            console.log("Glassbox Toggle is Off");
        }

        return;
    }

    if (window.tmxUICallback) {
        window.tmxUICallback(resp, false);
    }
}

/**
 * Implement fail safe function to load the biocatch javascript and
 * make it available through out the application
 * improvements: use the context in the future release to avoid exposing
 * the biocatch through the window object
 */

const BIO_CATCH_MAX_TRY_ALLOWED = 15;

function startBioCatchTimer(url: string, csid: string) {
    let timer: NodeJS.Timeout;

    let count = 0;

    function initBioCatch() {
        console.log("Biocatch: Initialization complete");
        window.BioCatchApi.setCustomerSessionId(csid);
        window.BioCatchApi.changeContext("sigin-in");
        console.log("Biocatch: Setup customer complete");
    }

    function onBioCatchLoadComplete(success: boolean, reason: string) {
        if (success) {
            if (window.cdApi != null) {
                window.BioCatchApi = window.cdApi;
                clearTimeout(timer);
                initBioCatch();
            }
        } else {
            if ("fatal" === reason) {
                clearTimeout(timer);
            }
        }
    }

    function onTick() {
        count = count + 1;
        if (window.BioCatchApi === undefined || window.BioCatchApi === null) {
            loadBioCatch(url, onBioCatchLoadComplete);
        } else {
            initBioCatch();
        }
        if (count >= BIO_CATCH_MAX_TRY_ALLOWED) {
            clearTimeout(timer);
            console.log(
                "Biocatch: Initialization max tries reached. Quitting initialization"
            );
        }
    }

    timer = setTimeout(onTick, 3000);
}

/**
 * Init ticket id(used in session query call) and tmxSessionId(used in profiling call) for TMX
 */
export function preLogin(uiCallback?: Function) {
    if (uiCallback) {
        window.tmxUICallback = uiCallback;
    }
    preLoginCtx
        ?.preLogin(_handlePreLoginResp)
        .then((response: any) => {
            if (response.status !== WAIT_FOR_IOS_CALLBACK) {
                _handlePreLoginResp(response.data);
            } else console.log("waiting for ios call", response);
        })
        .catch((error: any) => {
            console.log("errorPreLogin error - " + error);
            if (uiCallback) uiCallback(error, false);
        });
}

/**
 * handle logout
 */

function handleLogoutResp(resp?: { [key: string]: any }) {
    console.log("Logout...");

    securityCtx?.clearContext();

    loanContext?.clearContext();
    loanContext?.resetToggle();

    fx_pulse_Context?.resetToggle();
    wireInitiateContext?.clearContext();

    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.WEB) {
        routerCtx?.navigate(APP_LOCATIONS.SignOnPage);
    } else {
        const args = [{ foo: "bar" }];
        const biometricsInfo = securityCtx?.getBiometricsInfo();
        if (compareVersion("17") && biometricsInfo) {
            device.logoutBiometrics(
                () => {
                    console.log("biometrics logout successful");
                },
                () => {
                    console.log("biometrics logout unsuccessful");
                }
            );
            securityCtx?.setBiometricsInfo({
                ...biometricsInfo,
                ...{ autoLogin: false, setUpBiometrics: false },
            });
        }
        device.mobileTokenProvisioned(
            (response: any) => {
                // console.log('common mobileTokenProvisioned SUCCESS ...', response);
                if (!window.DEVICE_INFO_BAK) window.DEVICE_INFO_BAK = { ...response };
                console.log("common mobileTokenProvisioned SUCCESS ...");
                window.DEVICE_INFO = { ...window.DEVICE_INFO, ...response };
                if (compareVersion("16")) {
                    console.log("1 handleLogoutResp logout....");
                    routerCtx?.navigate(APP_LOCATIONS.HomePage);

                    clearTimeout(window.LOGOUT_TIMER_REACT);
                    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
                        console.log("Android goToNativePage......");
                        if (window.document.visibilityState === "visible") {
                            goToNativeMfa();
                        } else {
                            window.isLogoutFlag = true; //set flag is true and on MfaHome/SignOnPage check for event where visibility is true and then call the logic from goToNativeMfa()
                            console.log("visibilityState hidden");
                        }
                    } else {
                        console.log("iOS goToNativePage......");
                        device.goToNativePage();
                    }

                    if (compareVersion("18")) {
                        let dayOfWeek = moment(new Date()).tz("America/New_York").day();

                        const loadedInterval = 1000 * 60 * 60 * 24 * 7;
                        let reloadRequired =
                            new Date().getTime() - securityCtx.getLastLoadedTime() >=
                            loadedInterval;

                        if (reloadRequired || dayOfWeek === 6) {
                            console.log("reloadWebView ...");
                            device.reloadWebView();
                        }
                    }
                    return;
                }

                if (response.success) {
                    console.log("APP_LOCATIONS.MfaHome...");
                    routerCtx?.navigate(APP_LOCATIONS.MfaHome);
                } else {
                    console.log("APP_LOCATIONS.SignOnPage...");
                    routerCtx?.navigate(APP_LOCATIONS.SignOnPage);
                }
                if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
                    console.log("2 handleLogoutResp logout");
                    device.changeBrowserURL("mfa_logout", 1);
                }
                if (window.THE_DEVICE_TYPE === DEVICE_TYPE.IOS)
                    device.launchSecondBrowserURL("", 2);
                device.launchSecondBrowserURL("", 1);
            },
            (fail: any) => {
                console.log("common mobileTokenProvisioned FAILED.....");
                routerCtx?.navigate(APP_LOCATIONS.SignOnPage);
                if (compareVersion("16")) {
                    console.log("3 handleLogoutResp logout");
                    if (window.THE_DEVICE_TYPE === DEVICE_TYPE.ANDROID) {
                        console.log("Android goToNativePage......");
                        if (window.document.visibilityState === "visible") {
                            goToNativeMfa();
                        } else {
                            window.isLogoutFlag = true; //set flag is true and on MfaHome/SignOnPage check for event where visibility is true and then call the logic from goToNativeMfa()
                            console.log("visibilityState hidden");
                        }
                    } else {
                        console.log("iOS goToNativePage......");
                        device.goToNativePage();
                    }
                    return;
                }
                console.log("4 handleLogoutResp logout");
                device.changeBrowserURL("", 1);
            },
            args
        );
    }

    device.changeBioCatchContext("/logout");
}

export function logOut() {
    loginService
        ?.citibusinessMobileLogout(handleLogoutResp)
        .then((resp) => {
            if (resp.status !== WAIT_FOR_IOS_CALLBACK) {
                handleLogoutResp();
            } else {
                console.log("waiting for ios call", resp);
            }
        })
        .catch(() => {
            handleLogoutResp();
        });
}

export function goToSessionSuspendedPage() {
    routerCtx?.navigate(APP_LOCATIONS.SessionSuspended);
}

/**
 *
 * Decode html entity
 * @param text
 *
 * for example : decodeEntities('new name&#x2f;&#x2f;') -> 'new name//'
 */
export function decodeEntities(text: string) {
    if (!text) {
        return "";
    }

    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
}

export function handleBackOnLeftMenus() {
    console.log(
        "Go Back --> mobileApp",
        device.isMobile(),
        "isSignedIn?",
        securityCtx.isSignedIn(),
        "isShowMobileApp?",
        securityCtx.isShowMobileApp(),
        "isShowGenerateTokens?",
        window.isShowGenerateTokens
    );

    if (securityCtx.isSignedIn()) {
        routerCtx.navigate(APP_LOCATIONS.SignInHome);
    } else if (securityCtx?.isShowMobileApp()) {
        routerCtx.navigate(APP_LOCATIONS.MfaHome);
    } else if (window.isShowGenerateTokens) {
        device.goToNativePage(APP_LOCATIONS.MfaHome);
    } else {
        if (compareVersion("16")) {
            device.goToNativePage();
            return;
        }

        routerCtx?.navigate(APP_LOCATIONS.HomePage);
    }
}

/**
 * Format currency to USD
 * @param currency
 * @returns
 */

export function formatCurrencyToUSD(currency: number | string) {
    if (currency === undefined) {
        return "N/A";
    }

    let c: number | string = "";
    if (_.isString(currency)) {
        c = Number(currency);

        if (isNaN(c)) {
            return "N/A";
        }
    } else {
        c = currency;
    }

    return c.toFixed(2).replace(/\d+/, (n: string) => {
        const res = n.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        return res;
    });
}

export function formatCurrencyToUSDWithPrefix(currency: number | string) {
    const c = formatCurrencyToUSD(currency);
    if (c === "N/A") {
        return c;
    }
    const amt = Number(c);
    if (amt < 0) {
        return "-$" + Math.abs(amt)?.toFixed(2);
    }
    return "$" + c;
}
export function formatCurrencyToUSDWithNegativeAmount(
    currency: number | string
) {
    const c = formatCurrencyToUSD(currency);
    const amt = Number(c);
    if (amt < 0.01) {
        return "($" + Math.abs(amt)?.toFixed(2) + ")";
    }

    return "$" + c;
}

export function captilizeEachWordOfString(text: string) {
    const string = "N/A";
    if (!text || text.toLowerCase() === string.toLowerCase()) {
        return "N/A";
    }

    return text
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

export const camelize = (str?: any) => {
    if (str !== undefined) {
        // INSUFFICIENT_FUNDS / INSUFFICIENT FUNDS -> Insufficient Fund
        //let stat = str.toLowerCase();
        //let stat = str.replace(/_/g, ' ').toLowerCase();
        //return stat.charAt(0).toUpperCase() + stat.slice(1);
        let cStr = "";
        str = str.replace(/_/g, " ");
        str.split(" ").forEach(function (item: any, index: number) {
            cStr +=
                (index === 0 ? "" : " ") +
                item.charAt(0).toUpperCase() +
                item.slice(1).toLowerCase();
        });
        return cStr;
    } else {
        return "";
    }
};

export const considerDateIgnoringSecondsDiff = (
    value: any
): string | number => {
    //  let unixTime = moment.unix(epochFormat);
    //var etTime = unixTime.tz("America/New_York").format('MM/DD/YYYY');
    var etTime = moment.tz(value * 1000, "America/New_York").format("MM/DD/YYYY");
    etTime += " 0:01:59 am"; //DONE for grouping of the data on screen
    if (value === undefined || !value) {
        return 0;
    }
    let time = moment(etTime).unix() * 1000;
    //var testTimeDate = new Date(time)//moment.format(time, 'MM/DD/YYYY h:mm:ss a');
    //console.log(testTimeDate)
    //var testTime = moment.tz(time, 'America/New_York').format();
    //console.log(testTime)
    //let time = moment(etTime).unix() * 1000
    return time;
};

export const camelizeWithSpace = (str: any) => {
    if (!!str) {
        let stat = str.replace(/_/g, " ").toLowerCase();
        //    let stat=str.toLowerCase()
        return stat.charAt(0).toUpperCase() + stat.slice(1);
    } else {
        return "";
    }
};

export enum FlexibleAmountType {
    AVL_BALANCE_UPTO = "AVL_BALANCE_UPTO",
    TOTAL_AVL_BALANCE = "TOTAL_AVL_BALANCE",
    AVL_BALANCE_LESS = "AVL_BALANCE_LESS",
    PERCENT_AVL_BALANCE = "PERCENT_AVL_BALANCE",
    FIXED_AMOUNT = "FIXED",
}
export enum RecTransferDurationType {
    UNTIL_NOTIFIED = "UNTIL_NOTIFIED",
    NUM_PAYMENTS = "NUM_PAYMENTS",
    END_DATE = "END_DATE",
    PAYMENT_AMOUNT = "PAYMENT_AMOUNT",
}
export enum TransferFrequency {
    WEEKLY = "WEEKLY",
    BI_WEEKLY = "BI_WEEKLY",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    EVERY_SIX_MONTH = "EVERY_SIX_MONTH",
    ONCE_A_YEAR = "ONCE_A_YEAR",
    DAILY = "DAILY",
}

export const transform = (value: any): any => {
    if (!value) {
        return "---";
    }

    return value;
};
export const getEpochDate = (value: any): string | undefined => {
    let epochFormat = value / 1000;
    let unixTime = moment.unix(epochFormat);
    var etTime = unixTime.tz("America/New_York").format("MM/DD/YYYY");
    if (value === undefined || !value) {
        return "--";
    }
    return etTime;
};

/**
 * compare current app version with version
 *
 * return true if the current app version is equal or greater than version
 */
export function compareVersion(version: string) {
    const appVersion = window.DEVICE_INFO?.appVersion;

    return (
        _.isString(appVersion) &&
        appVersion.length > 0 &&
        _.isString(version) &&
        version.length > 0 &&
        getVersionNum(appVersion) >= getVersionNum(version)
    );
}

function getVersionNum(version: string) {
    const versionArr =
        version.indexOf("_") !== -1 ? version.split("_") : version.split(".");

    let result = 0;
    if (_.isArray(versionArr)) {
        result = _.reduce(
            versionArr,
            (memo, value, index: number) => {
                const weight = 100000 / Math.pow(10, index * 2);
                memo += parseInt(value) * weight;
                return memo;
            },
            0
        );
    }
    return result;
}

function goToNativeMfa() {
    console.log("goToNativeMfa...");
    device.goToNativePage("mfa_logout");
}

/**
 * Open cookie consent preference center
 **/
export function openCookieConsentPreferenceCenter() {
    const banner = document.getElementById("onetrust-banner-sdk");
    if (banner) {
        banner.setAttribute("style", "display: none;");
    }

    const prefpc = document.getElementById("onetrust-pc-sdk");
    if (prefpc) {
        prefpc.setAttribute(
            "style",
            "display: block !important; min-height:70%;max-height:100%"
        );
    }

    const filter = document.getElementsByClassName("onetrust-pc-dark-filter")[0];
    if (filter) {
        filter.setAttribute("style", "display: block !important;");
    }
}

/**
 * Focus to element with classname
 **/
export const focusToElementWithClassName = (
    className: string,
    withDelay: number
) => {
    _.delay(() => {
        const element: HTMLElement | null = document.querySelector(className);
        if (element) {
            element.setAttribute("tabIndex", "0");
            element.focus();
        }
    }, withDelay);
};

/**
 * Focus to element with id
 **/
export const focusToElementWithid = (id: string, withDelay: number) => {
    _.delay(() => {
        const element: HTMLElement | null = document.getElementById(id);
        if (element) {
            element.setAttribute("tabIndex", "0");
            element.focus();
        }
    }, withDelay);
};

export const handleTick =
    (setTimerValue: (timeLeft: number) => void) => (timeLeft: number) => {
        setTimerValue(timeLeft);
    };

/**
 * This is to check whether the transaction is ISO FED IRS Tax Payment
 * @param abaNumber
 * @param date
 * @param transferCurrencyIndicator
 * @returns
 */
export const isIRSTaxPayment = (
    abaNumber: any,
    date?: any,
    transferCurrencyIndicator?: any | undefined,
    transactionTypeEnum?: any | undefined
): boolean => {
    if (
        (transferCurrencyIndicator === undefined ||
            transactionTypeEnum === undefined ||
            transferCurrencyIndicator.toLowerCase() === "domestic" ||
            transactionTypeEnum ===
            kAPPROVALS.APPROVALS_TRANSACTION_TYPES.WIRES_NON_BATCH) &&
        abaNumber === "091036164" &&
        (!date || (date && moment(date).tz("America/New_York").format("MM-DD-YY") >=
            moment(1741608077000).tz("America/New_York").format("MM-DD-YY"))) &&
        wireInitiateContext.getIRSFedTaxPayToggle()
    ) {
        return true;
    } else {
        return false;
    }
};
