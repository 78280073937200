import _ from "lodash";
import http from "./api.core";
import { RequestMethod } from "./api.core";
import { AxiosResponse } from "axios";
import kPIWIK from "../common/constants/PIWIK";
import loginApi from './login.api';

let MOCKED_API: any

export interface CDCBResponse {

}

export class CDCBApi {

    constructor() {
        const isProd = (process.env.NODE_ENV === "production");
        const m = require(isProd ? "./mocks/prod.mock.ts" : "./mocks/mocks.ts").default
        // const m = require(isProd ? "./mocks/prod.mock.ts" : "./mocks/mocks.copy.ts").default
        MOCKED_API = m
    }

    getEncryptionSession(userId: string, url: string, callBack?: Function) {
        const params = new URLSearchParams()
        params.append("USER", userId);

        return http.makeHttpRequest(
            RequestMethod.POST,
            url,
            null,
            params,
            callBack,
            kPIWIK.ChannelEnum.CDCB_LOGIN,
            "getEncryptionSession",
            MOCKED_API?.ENCRYPTION_SESSION) as Promise<AxiosResponse<CDCBResponse>>;
    }

    login(userId: string, encPassword: string, otp1: string, callBack?: Function) {
        return loginApi.login("", "", "", otp1, callBack, false, "", "", "", "", userId, encPassword)
    }
}

const __instance__ = new CDCBApi();

export default __instance__;
